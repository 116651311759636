/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { shareBulkModalConfirmation } from "utils/confirm";

import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import { getProfileInfo } from "store/modules/profile/selectors";
import { connect } from "react-redux";
import { store } from "store";
import {
  setExcludedCandidates,
  setSelectAll
} from "store/modules/jobs/actions";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import useDebouncedSearch from "hooks/useDebouncedSearch.ts";
import { getCandidatesExportLink } from "store/modules/jobs/selectors";
import StartFirebase from "configs/firebase";
import { downloadAndDeleteLink } from "store/modules/сandidates/actions";
import CandidateActions from "./Actions";
import NameSearch from "./NameSearch";
import RatingSearch from "./RatingSearch";
import { downloadAndDeleteLinkFailed } from "../../../store/modules/сandidates/actions";

const CandidateSearch = ({
  t,
  fetchCandidatesList,
  updateCandidatesList,
  downloadCandidatesList,
  shareCandidatesListPublic,
  jobId,
  selected,
  setSelected,
  bulkShowcaseLink,
  bulkKey,
  clearSharableLink,
  setAvailableAnswerBulk,
  questions,
  setModal,
  isSelectedCandidatesLoading,
  isMaliciousFileMuted,
  rank,
  setRank,
  candidatesExportLink,
  clearSelectedStatus
}) => {
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);
  const [db, setDB] = useState();
  const [snapshot] = useObject((db && candidatesExportLink) ? ref(db, candidatesExportLink) : null);
  const { search: name, setSearch: setName, debouncedSearch } = useDebouncedSearch({
    delay: 750
  });

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    const exportedFile = snapshot?.val();
    const status = exportedFile?.status;

    if (status === "completed") {
      const reportKey = exportedFile?.link.split("/").slice(5).join("/").split("?")[0];
      const deleteUrl = `/api/job/<key>/candidates/bulk/info/csv/${reportKey}/delete`;
      store.dispatch(
        downloadAndDeleteLink({
          link: exportedFile?.link,
          deleteUrl,
          reportKey
        })
      );
    }

    if (status === "error") store.dispatch(downloadAndDeleteLinkFailed());
  }, [snapshot?.val()?.link, snapshot?.val()?.status]);

  const clearAll = useCallback(() => {
    setName("");
    setRank("");
  }, []);

  const handleAvailableAnswer = allowQuestions =>
    setAvailableAnswerBulk({
      questions: allowQuestions,
      isActive: true,
      bulkKey,
      candidates: selected?.map(a => a.id)
    });

  useEffect(() => {
    fetchCandidatesList({ jobId, name: debouncedSearch, rank });
  }, [debouncedSearch, rank]);

  useEffect(() => {
    if (bulkShowcaseLink) {
      shareBulkModalConfirmation(
        t("modals.showCase.people", { people: selected.length }),
        {
          bulkShowcaseLink,
          clearSharableLink: () => {
            clearSharableLink();
            setSelected([]);
            clearSelectedStatus("");
            store.dispatch(setExcludedCandidates([]));
            store.dispatch(
              setSelectAll({
                accepted: false,
                rejected: false,
                invited: false,
                received: false
              })
            );
          },
          setAvailableAnswer: handleAvailableAnswer,
          questions
        }
      );
    }
  }, [clearSharableLink, bulkShowcaseLink]);

  const handleUpdate = action => {
    updateCandidatesList({
      jobId,
      candidates: selected,
      action,
      name,
      rank
    });
    clearSelectedStatus("");
    setSelected([]);
    store.dispatch(setExcludedCandidates([]));
    return store.dispatch(
      setSelectAll({
        accepted: false,
        rejected: false,
        invited: false,
        received: false
      })
    );
  };

  const handleDownload = () => {
    downloadCandidatesList({
      jobId,
      candidates: selected
    });
    clearSelectedStatus("");
    setSelected([]);
    store.dispatch(setExcludedCandidates([]));
    store.dispatch(
      setSelectAll({
        accepted: false,
        rejected: false,
        invited: false,
        received: false
      })
    );
  };

  const handleDownloadClick = () => {
    if (isMaliciousFileMuted) {
      return handleDownload();
    }

    return setShowUnsafeModal(true);
  };
  const handleCancel = () => {
    setSelected([]);
    store.dispatch(setExcludedCandidates([]));
    clearSelectedStatus("");
    store.dispatch(
      setSelectAll({
        accepted: false,
        rejected: false,
        invited: false,
        received: false
      })
    );
  };

  const handleShowcase = () =>
    shareCandidatesListPublic({ jobId, candidates: selected });

  return (
    <Row className="row-search-container">
      {showUnsafeModal && (
        <UnsafeModal
          onDownload={handleDownload}
          handleCloseModal={() => setShowUnsafeModal(false)}
        />
      )}
      {selected.length ? (
        <CandidateActions
          handleUpdate={handleUpdate}
          handleDownload={handleDownloadClick}
          handleShowcase={handleShowcase}
          handleCancel={handleCancel}
          setModal={setModal}
          jobId={jobId}
          selectedLength={selected.length}
          name={name}
          rank={rank}
          loading={isSelectedCandidatesLoading}
        />
      ) : (
        <Row className="row-search">
          <label htmlFor="name-search" className="n-font-small label__search">
            {t("job.filter")}
          </label>
          <div className="search-container">
            <Row>
              <Col lg={4} className="rank-search">
                <NameSearch name={name} setName={setName} />
              </Col>
              <Col lg={8} className="rank-search">
                <RatingSearch
                  rank={rank}
                  setRank={setRank}
                  clearAll={clearAll}
                />
              </Col>
            </Row>
          </div>
        </Row>
      )}
    </Row>
  );
};

CandidateSearch.defaultProps = {
  bulkShowcaseLink: undefined,
  bulkKey: undefined,
  isSelectedCandidatesLoading: undefined,
  candidatesExportLink: ""
};

CandidateSearch.propTypes = {
  bulkKey: PropTypes.string,
  bulkShowcaseLink: PropTypes.string,
  clearSharableLink: PropTypes.func.isRequired,
  downloadCandidatesList: PropTypes.func.isRequired,
  fetchCandidatesList: PropTypes.func.isRequired,
  isMaliciousFileMuted: PropTypes.bool.isRequired,
  isSelectedCandidatesLoading: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rank: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAvailableAnswerBulk: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setRank: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  shareCandidatesListPublic: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateCandidatesList: PropTypes.func.isRequired,
  clearSelectedStatus: PropTypes.func.isRequired,
  candidatesExportLink: PropTypes.string
};

const mapStateToProps = state => ({
  isMaliciousFileMuted:
    getProfileInfo(state)?.is_malicious_file_message_muted || false,
  candidatesExportLink: getCandidatesExportLink(state)
});

export default connect(mapStateToProps)(withTranslation()(CandidateSearch));
