import { DownloadFilledIcon } from "mixins/svgIcons";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import { getProfileInfo } from "store/modules/profile/selectors";
import { connect } from "react-redux";
import useWindowResize from "hooks/useWindowResize.ts";

const DownloadAllButton = ({
  t,
  shouldShowDownloadAll = false,
  isMaliciousFileMuted = false,
  handleDownload
}) => {
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);

  const { isMobileAndTabletScreen } = useWindowResize();

  if (isMobileAndTabletScreen) {
    return <></>;
  }

  return (
    <>
      <button
        className="button__without-styles download flex items-center gap-2"
        style={{
          cursor: shouldShowDownloadAll ? "pointer" : "not-allowed"
        }}
        type="button"
        onClick={() => {
          if (shouldShowDownloadAll && !isMaliciousFileMuted) {
            setShowUnsafeModal(true);
          }

          if (shouldShowDownloadAll && isMaliciousFileMuted) {
            handleDownload();
          }
        }}
        aria-label="Download all"
      >
        {t("job.candidate.download.downloadAll")}
        <DownloadFilledIcon />
      </button>

      {showUnsafeModal && (
        <UnsafeModal
          handleCloseModal={() => setShowUnsafeModal(false)}
          onDownload={() => {
            handleDownload();
            setShowUnsafeModal(false);
          }}
        />
      )}
    </>
  );
};

DownloadAllButton.propTypes = {
  t: PropTypes.func.isRequired,
  shouldShowDownloadAll: PropTypes.bool,
  isMaliciousFileMuted: PropTypes.bool,
  handleDownload: PropTypes.func
};

DownloadAllButton.defaultProps = {
  shouldShowDownloadAll: false,
  isMaliciousFileMuted: false,
  handleDownload: () => {}
};

const mapStateToProps = state => ({
  isMaliciousFileMuted: getProfileInfo(state)?.is_malicious_file_message_muted || false
});

export default connect(mapStateToProps)(withTranslation()(DownloadAllButton));
