import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { JOB_STATUSES } from "configs/jobs/constants";
import TooltipInfo from "components/Common/Tooltip/Info";

const Job = ({ t, isLive = false, prefix = "" }) => (
  <TooltipInfo
    prefix="tooltip__icon-duplicate without-styles align-center md"
    position="right"
    message={t("job.table.tooltip.expired_closed")}
    disabled={![JOB_STATUSES.EXPIRED_CLOSED].includes(prefix)}
  >
    <span className={`status-job ${isLive ? JOB_STATUSES.LIVE : JOB_STATUSES.CLOSED} ${prefix}`}>
      {t(`job.table.${isLive ? JOB_STATUSES.LIVE : prefix || JOB_STATUSES.CLOSED}`)}
    </span>
  </TooltipInfo>
);

Job.defaultProps = {
  isLive: false,
  prefix: undefined
};

Job.propTypes = {
  t: PropTypes.func.isRequired,
  isLive: PropTypes.bool,
  prefix: PropTypes.string
};

export default withTranslation()(Job);
