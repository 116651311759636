/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { getRequestWithToken } from "sagas/api";
import { store } from "store";
import useDebouncedSearch from "hooks/useDebouncedSearch.ts";
import useInterviewTemplatesStore from "store/useTemplateJobStore.ts";
import BaseHeader from "./BaseHeader.tsx";

interface Suggestion {
  title: string;
}

const JobsTemplateHeader: React.FC = () => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { token } = store.getState()?.auth?.data ?? {};
  const { search, debouncedSearch, setSearch } = useDebouncedSearch({
    initialSearch: ""
  });

  const { getTemplates } = useInterviewTemplatesStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const fetchSuggestions = useCallback(
    debounce(async (query: string) => {
      if (!query || !token) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      try {
        const response = await getRequestWithToken({
          url: `/api/interview-templates/autocomplete?query=${encodeURIComponent(query)}`,
          token
        });

        setSuggestions(response?.data?.results?.map((item: Suggestion) => item.title) ?? []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    [token]
  );

  useEffect(() => {
    if (debouncedSearch) {
      fetchSuggestions(debouncedSearch);
      getTemplates({ query: debouncedSearch, page: 1 });
    } else {
      setSuggestions([]);
      getTemplates({ query: "", page: 1 });
    }
  }, [debouncedSearch, fetchSuggestions, getTemplates]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearch(suggestion);
    getTemplates({ query: suggestion, page: 1 });
    setShowSuggestions(false);
  };

  return (
    <div className="bg-white w-full">
      <div>
        <BaseHeader
          leftContent={(
            <div>
              <div className="font-medium text-sm pt-5">
                Interviews
              </div>
              <h1 className="text-3xl font-semibold text-gray-900">Templates Library</h1>
            </div>
          )}
          rightContent={(
            <div className="md:w-96 w-full md:pt-0 pt-4" ref={wrapperRef}>
              <div className="relative">
                <input
                  type="text"
                  value={search}
                  placeholder="Search Template Library..."
                  onChange={handleInputChange}
                  onFocus={() => setShowSuggestions(true)}
                  className="w-full  border border-gray-300 pl-4 pr-10 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  style={{
                    borderRadius: 30
                  }}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  {loading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-400" />
                  ) : (
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  )}
                </div>

                {showSuggestions && suggestions.length > 0 && (
                  <div className="absolute mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200 max-h-[200px] overflow-y-auto z-50">
                    <ul className="py-1 flex flex-col">
                      {suggestions.map(suggestion => (
                        <li
                          key={suggestion}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
                          onClick={() => handleSuggestionClick(suggestion)}
                          role="button"
                          tabIndex={0}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default React.memo(JobsTemplateHeader);
