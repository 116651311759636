/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Add, TrashBin } from "mixins/svgIcons";
import { getFileBase64 } from "mixins/helpers";

import Thumb from "./Thumb";

const Enabled = ({
  t,
  valid,
  fieldName,
  fieldValue,
  setFieldValue,
  setFieldTouched,
  currentLogoSrc = null
}) => {
  const inputChoseFile = useRef(null);
  const [dataFile, setDataFile] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    getFileBase64(dataFile).then(data => {
      setLogo(data);
      setFieldValue(fieldName, dataFile);
    });
  }, [dataFile]);

  useEffect(() => {
    setLogo(currentLogoSrc);
  }, [currentLogoSrc]);

  const handleClassName = valid
    ? "logo-wrapper n-bg-white"
    : "logo-wrapper n-bg-red-100";

  const handleDelete = () => {
    setLogo(null);
    setDataFile(null);
    setFieldValue(fieldName, "");
    setFieldTouched(fieldName, true);
    inputChoseFile.current.value = null;
  };

  return (
    <div className={handleClassName}>
      <p className="logo-button-text flex flex-row">
        <Add />
        <span>{t("input.companyUploadTitle")}</span>
      </p>

      <input
        id="comapny-logo"
        type="file"
        name="logo"
        ref={inputChoseFile}
        accept=".jpeg, .png"
        onChange={event => {
          setDataFile(event.currentTarget.files[0]);
        }}
        onBlur={() => {
          setFieldTouched(fieldName, true);
        }}
      />

      {valid && fieldValue && (
        <>
          <button
            type="button"
            onClick={handleDelete}
            className="button__without-styles logo-button-delete"
          >
            <TrashBin />
          </button>

          <Thumb
            logo={logo || fieldValue}
            clicked={() => inputChoseFile.current.click()}
            blured={() => setFieldTouched(fieldName, true)}
          />
        </>
      )}
    </div>
  );
};

Enabled.defaultProps = {
  valid: false,
  currentLogoSrc: null,
  setFieldValue: undefined,
  setFieldTouched: undefined
};

Enabled.propTypes = {
  t: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  currentLogoSrc: PropTypes.string,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func
};

export default withTranslation()(Enabled);
