import React, { useMemo, useState, useLayoutEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from "react-select";
import classnames from "classnames";

import { Close } from "mixins/svgIcons";

import "./styles.scss";
import { store } from "store";
import { clearCompanies } from "store/modules/companies/actions";

const UserSelect = ({
  handleOnboarding,
  usersList,
  currentUser,
  isAdmin,
  logOutUser,
  fullName,
  isAppEnabled,
  isSubscriptionFailed,
  isSetupCompleted,
  profileInfo,
  isTrialOrPaid,
  getCurrentAgency,
  fetchProfileInfo,
  isAccountVerified
}) => {
  const [isMenuOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const userSelectRef = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      if (userSelectRef.current?.style) {
        userSelectRef.current.style.setProperty(
          "bottom",
          `calc(100vh - ${window.innerHeight}px)`
        );
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleSelectUser = ({ value }) => {
    store.dispatch(clearCompanies());
    getCurrentAgency({ agencyKey: value.key });
    handleOnboarding(value);
  };

  const handleOpenMenu = () => {
    setOpen(!isMenuOpen);
  };

  const options = useMemo(
    () =>
      usersList &&
      usersList.map(user => ({
        label: user.name,
        value: user
      })),
    [usersList]
  );

  const value = { label: currentUser.name, value: currentUser };
  const avatarName = fullName
    ? fullName
      ?.match(/\b(\w)/g)
      ?.join("")
      ?.slice(0, 2)
    : "";
  const menuClassName = classnames("nav-mobile-menu", {
    "nav-mobile-menu--open": isMenuOpen
  });

  return (
    <>
      {isMenuOpen && (
        <div
          className="nav-overlay"
          onClick={handleOpenMenu}
          role="presentation"
        />
      )}

      <div className="nav-user-select" ref={userSelectRef}>
        {options.length === 1 ? (
          <div className="nav-user-select-single nav-user-select-single-mobile">
            <p>{options[0].label}</p>
          </div>
        ) : (
          <Select
            className="comapany__chart-select"
            classNamePrefix="select"
            options={options}
            isSearchable={false}
            onChange={handleSelectUser}
            menuPlacement="top"
            value={value}
          />
        )}
        <button
          type="button"
          className="nav-user-select__avatar"
          onClick={handleOpenMenu}
        >
          <span className="n-font-small n-font-semi-bold n-uppercase n-text-center white">
            {avatarName}
          </span>
        </button>
      </div>

      <div className={menuClassName}>
        <div className="nav-mobile-menu__avatar">
          <div className="nav-user-select__avatar">
            <span className="n-font-small n-font-semi-bold n-uppercase n-text-center white">
              {avatarName}
            </span>
          </div>
        </div>

        <div className="nav-mobile-menu__wrapper">
          <button
            type="button"
            className="nav-mobile-menu__close"
            onClick={handleOpenMenu}
          >
            <Close fill="#5A2AF1" />
          </button>

          <p className="n-font-large n-font-medium-weight n-text-center nav-mobile-menu__hello">
            {`Hello ${currentUser.name}`}
          </p>

          <nav className="nav-mobile-navigation">
            {isAppEnabled && (
              <NavLink
                to="/profile"
                className="n-font-large n-font-regular n-text-center"
                onClick={() => fetchProfileInfo()}
              >
                {t("link.profile")}
              </NavLink>
            )}

            {profileInfo.mfa_available && (
              <NavLink
                to="/security"
                className="n-font-large n-font-regular n-text-center"
              >
                {t("link.security")}
              </NavLink>
            )}

            {!isSubscriptionFailed && isSetupCompleted && isAdmin && isAccountVerified && (
              <NavLink
                to="/users"
                className="n-font-large n-font-regular n-text-center"
              >
                {t("header.users")}
              </NavLink>
            )}

            {isAppEnabled && isTrialOrPaid && isAccountVerified && (
              <NavLink
                to="/libraries"
                className="n-font-large n-font-regular n-text-center"
              >
                {t("link.libraries")}
              </NavLink>
            )}

            {isAdmin && isAppEnabled && (
              <NavLink
                to="/scorecards"
                className="n-font-large n-font-regular n-text-center"
              >
                {t("link.scorecards")}
              </NavLink>
            )}
            <a
              href="https://support.willo.video"
              target="_blank"
              rel="noopener noreferrer"
              className="n-font-large n-font-regular n-text-center"
            >
              {t("link.support")}
            </a>
            <button
              type="button"
              onClick={logOutUser}
              className="n-font-large n-font-regular nav-mobile-navigation-logout"
            >
              {t("link.logOut")}
            </button>
          </nav>
        </div>
      </div>
    </>
  );
};

UserSelect.defaultProps = {
  usersList: undefined,
  fullName: ""
};

UserSelect.propTypes = {
  profileInfo: PropTypes.shape({
    mfa_available: PropTypes.bool
  }).isRequired,
  getCurrentAgency: PropTypes.func.isRequired,
  handleOnboarding: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  currentUser: PropTypes.shape({ name: PropTypes.string }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logOutUser: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  isAppEnabled: PropTypes.bool.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  isTrialOrPaid: PropTypes.bool.isRequired,
  fetchProfileInfo: PropTypes.func.isRequired,
  isAccountVerified: PropTypes.bool.isRequired
};

export default UserSelect;
