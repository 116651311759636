/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
import axios from "axios";
import moment from "moment";
import {
  takeEvery,
  takeLatest,
  select,
  call,
  put,
  all
} from "redux-saga/effects";

/* eslint-disable-next-line */
import { history } from "store";
import {
  isCandidateLoading,
  isVideoUploading,
  isInfoUpdating,
  fetchCandidateInfo,
  fetchCandidateInfoResponse,
  fetchCandidateInfoError,
  updateCandidateInfo,
  updateCandidateInfoResponse,
  updateCandidateInfoError,
  updateCandidateLang,
  updateCandidateLangResponse,
  updateCandidateLangError,
  fetchCandidateJobInfoResponse,
  fetchCandidateJobInfoError,
  fetchQuestionsAnswers,
  fetchQuestionsAnswersResponse,
  fetchQuestionsAnswersError,
  updateCandidateHistory,
  updateCandidateHistoryResponse,
  updateCandidateHistoryError,
  saveAnswer,
  saveAnswerResponse,
  saveAnswerError,
  saveOnAmazonS3,
  saveOnAmazonS3Response,
  saveOnAmazonS3Error,
  changeCandidateFile,
  changeCandidateFileError,
  fetchCandidatesStats,
  fetchCandidatesStatsResponse,
  fetchCandidatesStatsError,
  clearUploadInfo,
  retakeAnswer,
  retakeAnswerResponse,
  retakeAnswerError,
  clearIsLoading,
  setSavedAnswers,
  fetchTransferredCandidateInfo,
  processAnswers,
  downloadAll,
  downloadAllError,
  downloadAllResponse,
  downloadAndDeleteLink,
  downloadAndDeleteLinkResponse,
  downloadAndDeleteLinkError,
  fetchHintsAndTips,
  fetchHintsAndTipsError,
  fetchHintsAndTipsResponse,
  saveShareCodeResponse,
  saveShareCodeError,
  saveShareCode,
  getIdvCheckDataLoading,
  getIdvCheckDataError,
  getIdvCheckDataResponse,
  getIdvCheckData,
  updateIdvSkipped,
  updateIdvSkippedError,
  initQuestion,
  videoAttemptResponse,
  saveBlobOnAmazonS3,
  fetchAllSavedAnswers,
  saveDeviceInfo,
  updateCandidateFileError,
  updateCandidateFile,
  setCurrentCandidateId
} from "store/modules/сandidates/actions";
import {
  fetchCommonJobResponse,
  setNewCandidateInfo
} from "store/modules/common/actions";
import {
  getCandidateJobIdByKey,
  getCandidateToken,
  getCandidateTokenByKey,
  getSavedAnswers
} from "store/modules/сandidates/selectors";
import { getToken } from "store/modules/auth/selectors";

/* eslint-disable-next-line */
import {
  getRequestWithToken,
  postRequestWithToken,
  putRequestWithToken,
  uploadRequestWithToken,
  deleteRequestWithToken,
  getRequest,
  patchRequestWithToken
} from "./api";

/* eslint-disable-next-line */
import { recordEvent } from "utils/analytics";
import {
  getIdvSession,
  getIdvSessionError,
  getIdvSessionLoading,
  setIdvSession
} from "store/modules/idv/actions";
import { isEmpty, isNull, set } from "lodash";
import {
  ANSWER_STATUS,
  CANDIDATE_INTERVIEW_SUBMITTED,
  CANDIDATE_INTERVIEW_CLOSED,
  IDV_TYPE,
  DBS_TYPE,
  ALL_IDENTITY_VERIFICATION_TYPES
} from "configs/jobs/constants";
import { logErrors } from "mixins/helperLogging";
import { fetchCandidateAnswers, isJobCandidateLoading } from "store/modules/jobs/actions";
import { isAutoSaveBlobEnabled } from "mixins/helperVideoRecording";
import { getCommonJob } from "store/modules/common/selectors";
import { isAndroid } from "react-device-detect";
import { saveCandidatesExportLink } from "../store/modules/jobs/actions";
import { downloadAndDeleteLinkFailed, updateIsOpenVideoArchives } from "../store/modules/сandidates/actions";

let cancelTokenSource = null;

const CLOSED_STATUSES = [
  "closed",
  "expired_closed",
  "interview_closed"
];

export function* handleCandidateError(error, context) {
  logErrors({ error: error?.originalError || error, context });

  if (CLOSED_STATUSES.includes(error?.response?.data?.error) ||
    CLOSED_STATUSES.includes(error?.response?.data?.status) ||
    error?.response?.data?.agency_blocked) {
    yield call(history.push, "/interview-closed");
    throw error;
  }

  if ([CANDIDATE_INTERVIEW_SUBMITTED].includes(error?.response?.data?.error) ||
      [CANDIDATE_INTERVIEW_SUBMITTED].includes(error?.response?.data?.status)) {
    yield call(history.push, `/candidate/interview-submitted/${context.userId}`);
    throw error;
  }

  if ([401, 404].includes(error?.response?.status)) {
    yield put(setCurrentCandidateId(""));
    yield call(history.push, "/page-not-found");
    throw error;
  }

  throw error;
}

export function* fetchQuestionsAnswersWorker({
  payload: { userId = "", jobId = "" }
}) {
  try {
    const token = yield select(getCandidateToken);

    const response = yield getRequestWithToken({
      url: `/api/questions/job/${jobId}/interviewer/${userId}/`,
      token
    });

    yield put(
      fetchQuestionsAnswersResponse({
        userId,
        candidateQuestions: response?.data
      })
    );
  } catch (error) {
    console.log(
      "Failed to fetch question answers",
      error
    );

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId, jobId } });

    yield put(
      fetchQuestionsAnswersError({
        userId,
        error: error?.response?.data?.error || error?.data?.message || error?.message || "errors.incorectUser"
      })
    );
  }
}

export function* fetchCandidateAnswerWorker({ userId, jobId, questionId }) {
  let response = {};
  try {
    const token = yield select(getCandidateToken);

    response = yield call(getRequestWithToken, {
      url: `/api/candidates/${userId}/job/${jobId}/question/${questionId}/`,
      token
    });

    yield put(retakeAnswerResponse({ userId, answer: response?.data }));
  } catch (error) {
    console.log(
      "Failed to fetch candidate answers"
    );

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    if ([401, 404]?.includes(error?.response?.status)) {
      yield put(setCurrentCandidateId(""));
      return history.push("/page-not-found");
    }

    logErrors({ error, context: { userId, jobId, questionId } });

    yield put(retakeAnswerError({ userId, success: "errors.companyChart" }));
    yield put(
      fetchCandidateInfoError({
        userId,
        error: error?.response?.data?.error || error?.data?.message || error?.message || "errors.incorectUser"
      })
    );
  }

  return response?.data;
}

// eslint-disable-next-line no-unused-vars
function* fetchCandidateSavedAnswersWorker({
  userId, jobId, questions, shouldIncludeUnfinished = false, callback = null
} = {}) {
  const answers = yield select(getSavedAnswers);

  const answerResponses = yield all(
      questions?.map(question => {
        const answerItem = answers.find(a => a.key === question.key);
        if (question.status === ANSWER_STATUS.received) {
          if (answerItem) {
            return answerItem;
          }
          return fetchCandidateAnswerWorker({
            userId,
            jobId,
            questionId: question.question_key
          });
        }
        return fetchCandidateAnswerWorker({
          userId,
          jobId,
          questionId: question.question_key
        });
      }

      )
  );

  const finishedAnswers = answerResponses?.filter(answer => {
    if ([...ALL_IDENTITY_VERIFICATION_TYPES, "text"].includes(answer?.question?.answer_type)) {
      return answer?.is_finished;
    }

    return (
      (answer?.remote_link && answer?.is_finished) ||
      (answer?.remote_link && answer?.media_extension) ||
      (isNull(answer?.remote_link) && answer?.is_finished)
    );
  });

  yield put(
    setSavedAnswers({
      userId,
      answers: shouldIncludeUnfinished ? answerResponses ?? [] : finishedAnswers
    })
  );

  if (typeof callback === "function") yield call(callback, { answerResponses: finishedAnswers });
}

export function* fetchAllCandidateSavedAnswersWorker({
  payload: { userId, jobId, candidateToken, shouldIncludeUnfinished = false, callback = null }
} = {}) {
  try {
    if (candidateToken?.length > 0) {
      const questions = yield getRequestWithToken({
        url: `/api/candidates/${userId}/answers-statuses/`,
        token: candidateToken
      });

      if (questions?.data?.length && userId !== "" && jobId !== "") {
        yield call(fetchCandidateSavedAnswersWorker, {
          userId,
          jobId,
          questions: questions?.data,
          shouldIncludeUnfinished,
          callback
        });
      }
    }
    yield put(isJobCandidateLoading(false));
  } catch (error) {
    logErrors({ error, context: { userId, jobId } });

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    if ([401]?.includes(error?.response?.status)) {
      yield put(setCurrentCandidateId(""));
      return history.push("/page-not-found");
    }
  }
}

function* fetchCandidateJobInfo({ userId = "", jobId = "", candidateToken } = {}) {
  try {
    let dataResponse = {};
    const commonJob = yield select(getCommonJob);

    if (!userId || userId === "" || !jobId || jobId === "") {
      yield put(setCurrentCandidateId(""));
      yield call(history.push, "/page-not-found");
      return true;
    }

    if (!isEmpty(commonJob)) {
      dataResponse = commonJob;
    } else {
      const response = yield getRequestWithToken({
        url: `/api/job/public/${jobId}/`,
        token: candidateToken
      });

      dataResponse = response?.data;

      yield put(fetchCommonJobResponse(response?.data));
    }

    const filteredQuestions = dataResponse?.questions?.sort(
      (a, b) => a?.order - b?.order
    );

    const filteredResponse = {
      ...dataResponse,
      questions: filteredQuestions
    };

    yield put(
      fetchCandidateJobInfoResponse({
        userId,
        candidateJobInfo: filteredResponse
      })
    );

    yield call(fetchAllCandidateSavedAnswersWorker, { payload: { userId, jobId, candidateToken } });
  } catch (error) {
    yield put(
      fetchCandidateJobInfoError({
        userId,
        error: error?.response?.data?.error || error?.response?.data?.status || error?.data?.message || error?.message || "errors.incorectUser"
      })
    );

    yield call(handleCandidateError, error, { userId, jobId });
  }
}

export function* fetchCandidateInfoWorker({
  payload: { userId, jobId, candidateToken } = {}
}) {
  try {
    yield put(
      isCandidateLoading({
        isLoading: true,
        userId
      })
    );

    yield put(
      fetchCandidateInfoResponse({
        userId,
        candidateInfo: {}
      })
    );

    yield call(fetchCandidateJobInfo, {
      userId,
      jobId,
      candidateToken
    });

    const response = yield getRequestWithToken({
      url: `/api/candidates/${userId}/`,
      token: candidateToken
    });

    yield put(
      fetchCandidateInfoResponse({
        userId,
        candidateInfo: response?.data
      })
    );
    return response?.data;
  } catch (error) {
    logErrors({ error, context: { userId, jobId } });

    yield put(
      fetchCandidateInfoError({
        userId,
        error:
          [CANDIDATE_INTERVIEW_SUBMITTED, CANDIDATE_INTERVIEW_CLOSED]
            ?.includes(error?.response?.data?.error)
            ? error?.response?.data?.error
            : "errors.incorectUser"
      })
    );

    yield put(
      isCandidateLoading({
        isLoading: false,
        userId
      })
    );

    yield call(handleCandidateError, error, { userId, jobId });
  } finally {
    yield put(
      isCandidateLoading({
        isLoading: false,
        userId
      })
    );
  }
}

export function* fetchTransferredCandidateInfoWorker({
  payload: { userId, jobId, token, questionId, isShareCode = false } = {}
}) {
  try {
    yield put(setNewCandidateInfo({ userId, jobId, candidateToken: token }));
    const candidate = yield call(fetchCandidateInfoWorker, {
      payload: { userId, jobId, candidateToken: token }
    });

    if (candidate) {
      history.push(
        isShareCode === true && typeof isShareCode === "boolean"
          ? `/candidate/video-questions/create/${userId}/${jobId}/${questionId}/share-code`
          : questionId
            ? `/candidate/video-questions/create/${userId}/${jobId}/${questionId}`
            : `/candidate/video-questions/preview/${userId}`
      );
    }
  } catch (error) {
    console.log("Failed to transfer candidate");

    yield call(handleCandidateError, error, { userId, jobId });
  }
}

export function* updateCandidateInfoWorker({
  payload: {
    userId,
    availabilities,
    full_name,
    email,
    phone,
    status,
    position,
    successCallback,
    errorCallback
  } = {}
}) {
  try {
    yield put(
      isInfoUpdating({
        userId,
        isUpdating: true
      })
    );

    const token = yield select(getCandidateToken);

    const newPayload = {
      availabilities,
      position,
      name: full_name,
      email,
      phone,
      status,
      utc_minutes_delta: moment().utcOffset()
    };

    yield patchRequestWithToken({
      url: `/api/candidates/${userId}/`,
      token,
      payload: newPayload
    });

    if (typeof successCallback === "function") yield call(successCallback);

    yield put(updateCandidateInfoResponse({ userId }));

    recordEvent("User", "InterviewComplete");
  } catch (error) {
    console.log(
      "Failed to update candidate info after interview user id",
      error
    );

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({
      error,
      payload: {
        userId,
        availabilities,
        full_name,
        email,
        phone,
        status,
        position
      },
      context: {
        userId,
        email
      }
    });

    const errorMessage =
      error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED
        ? "This interview has already been submitted. You cannot update any of your answers anymore."
        : error?.message || "errors.updateUser";

    yield put(
      updateCandidateInfoError({
        userId,
        error: errorMessage
      })
    );

    if (typeof errorCallback === "function") yield call(errorCallback);
  } finally {
    yield put(
      isInfoUpdating({
        userId,
        isUpdating: false
      })
    );
  }
}

export function* updateCandidateLangWorker({
  payload: { userId, language } = {}
}) {
  try {
    const token = yield select(getCandidateToken);

    const response = yield patchRequestWithToken({
      url: `/api/candidates/${userId}/`,
      token,
      payload: { selected_language: language }
    });

    yield put(
      updateCandidateLangResponse({
        userId,
        selected_language: response?.data?.selected_language
      })
    );
  } catch (error) {
    yield put(
      updateCandidateLangError({
        userId,
        error
      })
    );

    yield call(handleCandidateError, error, { userId });
  }
}

export function* initQuestionWorker({
  payload: { questionId = "", userId = "", jobId = "", shouldSkipRedirectToIDV = false }
}) {
  try {
    let token = yield select(getCandidateTokenByKey, userId);

    if (isEmpty(token)) {
      token = yield select(getCandidateToken);
    }

    if (!isEmpty(token)) {
      const response = yield call(getRequestWithToken, {
        url: `/api/candidates/${userId}/job/${jobId}/question/${questionId}/`,
        token
      });

      const { data } = response;

      if (isAndroid && data?.remote_link) yield put(retakeAnswerResponse({ userId, answer: data }));

      if ([IDV_TYPE, DBS_TYPE]?.includes(data?.question?.answer_type) && !shouldSkipRedirectToIDV) {
        return history.push({
          pathname: `/candidate/video-questions/create/${userId}/${jobId}/${data?.question?.key}/idv-check`
        });
      }
    }
  } catch (error) {
    yield call(handleCandidateError, error, { userId, jobId, questionId });
  }
}

// eslint-disable-next-line
export function* saveAnswerWorker({
  payload: {
    questionId = "",
    is_finished,
    friendly_name,
    userId,
    spent_time,
    html_text,
    idv_citizenship_iso_code,
    text,
    is_skipped,
    onUploadProgress,
    callback,
    errorCallback,
    shouldSkipUploadingPage = false,
    jobId = null,
    is_reloaded = false,
    type_specific_data,
    cancelToken = undefined
  } = {}
} = {}) {
  try {
    if (typeof callback === "function" && !shouldSkipUploadingPage) {
      yield put(
        isVideoUploading({
          userId,
          isVideoLoading: true
        })
      );
    }

    const stateJobId = yield select(getCandidateJobIdByKey, userId);
    const token = yield select(getCandidateTokenByKey, userId);

    const response = yield call(postRequestWithToken, {
      url: `/api/candidates/${userId}/job/${jobId ?? stateJobId}/question/${questionId}/`,
      token,
      payload: {
        is_finished,
        friendly_name,
        spent_time,
        html_text,
        text,
        idv_citizenship_iso_code,
        is_skipped,
        is_reloaded,
        type_specific_data
      },
      onUploadProgress,
      cancelTokenSource: cancelToken
    });

    yield put(
      saveAnswerResponse({
        answer: response?.data,
        userId
      })
    );

    if (typeof callback === "function" && !shouldSkipUploadingPage) {
      yield put(
        isVideoUploading({
          userId,
          isVideoLoading: false
        })
      );
    }

    if (typeof callback === "function") yield call(callback, { response: response?.data });
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({
      error,
      payload: {
        questionId,
        is_finished,
        friendly_name,
        userId,
        spent_time,
        html_text,
        idv_citizenship_iso_code,
        text,
        is_skipped,
        onUploadProgress,
        callback
      },
      context: {
        userId,
        questionId
      }
    });

    // @TODO: Change error message
    yield put(
      saveAnswerError({
        userId,
        error: "errors.saveAnswerFailed"
      })
    );

    if (typeof errorCallback === "function") yield call(errorCallback, { error });
  }
}

function* saveVideoExtension({
  jobId,
  userId,
  questionId,
  mediaExtension,
  name = "",
  size = 0,
  isMuted,
  is_reloaded,
  cancelToken = undefined
} = {}) {
  try {
    const token = yield select(getCandidateToken);

    yield put(clearUploadInfo({ userId }));

    const response = yield call(postRequestWithToken, {
      url: `/api/candidates/${userId}/job/${jobId}/question/${questionId}/`,
      token,
      payload: {
        media_extension: mediaExtension || "webm",
        friendly_name: name,
        size,
        is_muted: isMuted,
        is_reloaded
      },
      cancelTokenSource: cancelToken
    });

    return response;
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      logErrors({
        error: set(error, "response.data.error", `Closed Interview: Failed media. Question id: ${questionId}, name: ${name}`),
        payload: {
          jobId,
          userId,
          questionId,
          mediaExtension,
          name,
          size
        },
        context: {
          userId,
          questionId,
          jobId
        }
      });

      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      logErrors({
        error: set(error, "response.data.error", `Interview Submitted: Failed media. Question id: ${questionId}, name: ${name}`),
        payload: {
          jobId,
          userId,
          questionId,
          mediaExtension,
          name,
          size
        },
        context: {
          userId,
          questionId,
          jobId
        }
      });

      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({
      error,
      payload: {
        jobId,
        userId,
        questionId,
        mediaExtension,
        name,
        size
      }
    });
  }
}

export function* saveDeviceInfoWorker({
  payload
} = {}) {
  const { userId, deviceInfo } = payload;

  try {
    const token = yield select(getCandidateToken);

    if (!token) return;

    const response = yield call(postRequestWithToken, {
      url: `/api/candidates/${userId}/device-info/`,
      token,
      payload: deviceInfo
    });

    return response;
  } catch (error) {
    logErrors({
      error, payload, context: { userId }
    });
  }
}

function* fetchPredefinedUrl({ userId, jobId, questionId, cancelToken = undefined }) {
  try {
    yield put(
      isCandidateLoading({
        isLoading: true,
        userId
      })
    );

    const token = yield select(getCandidateToken);

    const response = yield getRequestWithToken({
      url: `/api/candidates/${userId}/job/${jobId}/question/${questionId}/upload-info/`,
      token,
      cancelTokenSource: cancelToken
    });

    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(
      "Could not get predefined url",
      error
    );

    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message ||
      "errors.saveAnswerFailed";

    if (typeof errorMessage !== typeof undefined
      && !errorMessage?.includes("Operation canceled due to new request.")) {
      logErrors({ error, context: { userId, jobId, questionId } });
    }
  } finally {
    yield put(
      isCandidateLoading({
        isLoading: false,
        userId
      })
    );
  }

  return false;
}

export function* saveOnAmazonS3Worker({
  payload: {
    jobId = "",
    userId = "",
    questionId = "",
    mediaExtension,
    name,
    size,
    blob,
    callback,
    onUploadProgress,
    spentTime,
    isMuted,
    isAllowedEmptyBlob = false,
    cancelToken = undefined,
    is_reloaded = false
  }
}) {
  try {
    if (!questionId) {
      return history.push("/page-not-found");
    }

    yield put(
      isVideoUploading({
        userId,
        isVideoLoading: true
      })
    );

    if (blob && (!blob.size || blob.size <= 0) && !blob.length && !isAllowedEmptyBlob) {
      throw new TypeError("errors.videoUrl");
    }

    yield call(saveVideoExtension, {
      jobId,
      userId,
      questionId,
      mediaExtension,
      name,
      size,
      isMuted,
      is_reloaded,
      cancelToken
    });

    const { url = "", fields = {} } = yield call(fetchPredefinedUrl, {
      userId,
      jobId,
      questionId,
      cancelToken
    });

    const responseVideoUpload = yield uploadRequestWithToken({
      fullUrl: `${url}`,
      method: "POST",
      payload: {
        ...fields,
        file: blob
      },
      onUploadProgress,
      cancelTokenSource: cancelToken
    });

    if (
      responseVideoUpload.status === 204 ||
      responseVideoUpload.status === 200
    ) {
      yield call(saveAnswerWorker, {
        payload: {
          userId,
          jobId,
          questionId,
          spent_time: spentTime,
          is_finished: true,
          is_reloaded,
          shouldSkipUploadingPage: false,
          callback,
          cancelToken
        }
      });

      yield put(
        saveOnAmazonS3Response({
          userId,
          success: "Answer successfully uploaded"
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message ||
      "errors.saveAnswerFailed";

    if (typeof errorMessage !== typeof undefined
      && !errorMessage?.includes("Operation canceled due to new request.")) {
      yield put(
        saveOnAmazonS3Error({
          userId,
          error: errorMessage
        })
      );

      console.log(
        `Failed to save to s3. Error Message: ${error
          ?.response?.data?.message ||
          error?.response?.data ||
          error}`
      );

      if (error?.message !== "Operation canceled due to new request.") logErrors({ error, payload: JSON.stringify(blob), context: { userId, jobId, questionId } });
    }
  } finally {
    cancelTokenSource = null;
  }
}

export function* saveBlobOnAmazonS3Worker({
  payload: {
    jobId = "",
    userId = "",
    questionId = "",
    mediaExtension,
    name,
    size,
    blob,
    isMuted,
    callback,
    onUploadProgress,
    shouldSkipUploadingPage = false,
    cancelToken
  }
}) {
  try {
    if (!questionId) {
      return history.push("/page-not-found");
    }

    if (!isAutoSaveBlobEnabled && blob && (!blob.size || blob.size <= 0) && !blob.length) {
      throw new TypeError("errors.videoUrl");
    }

    if (!shouldSkipUploadingPage) {
      yield put(
        isVideoUploading({
          userId,
          isVideoLoading: true
        })
      );
    }

    const response = yield call(saveVideoExtension, {
      jobId,
      userId,
      questionId,
      mediaExtension,
      name,
      size,
      isMuted,
      cancelToken
    });

    const { url = "", fields = {} } = yield call(fetchPredefinedUrl, {
      userId,
      jobId,
      questionId,
      cancelToken
    });

    const responseVideoUpload = yield uploadRequestWithToken({
      fullUrl: `${url}`,
      method: "POST",
      payload: {
        ...fields,
        file: blob
      },
      cancelTokenSource: cancelToken,
      onUploadProgress
    });

    if (
      responseVideoUpload.status === 204 ||
      responseVideoUpload.status === 200
    ) {
      yield put(
        saveAnswerResponse({
          answer: response?.data,
          userId
        })
      );

      yield put(
        saveOnAmazonS3Response({
          userId,
          success: "Answer successfully uploaded"
        })
      );

      if (typeof callback === "function") yield call(callback, { remoteLink: response?.data?.remote_link, data: response?.data });
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message ||
      "errors.saveAnswerFailed";

    console.log(
        `Failed to save to s3. Error Message: ${error}`
    );

    // handle network errors to retry again
    if (typeof errorMessage !== typeof undefined
        && !errorMessage?.includes("Operation canceled due to new request.")) {
      yield put(
        saveOnAmazonS3Error({
          userId,
          error: errorMessage
        })
      );

      if (error?.message !== "Operation canceled due to new request.") logErrors({ error, payload: JSON.stringify(blob), context: { userId, jobId, questionId } });
    }
  }
}

export function* changeCandidateFileWorker({
  payload: {
    jobId = "",
    userId = "",
    questionId = "",
    mediaExtension,
    name,
    size,
    blob,
    callback,
    onUploadProgress,
    isRecruiter
  }
}) {
  try {
    if (blob && (!blob.size || blob.size <= 0)) {
      throw new TypeError("errors.fileDefaultError");
    }

    const token = yield select(getToken);
    onUploadProgress(0);
    yield put(changeCandidateFileError(""));
    yield putRequestWithToken({
      url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/`,
      token,
      payload: {
        media_extension: mediaExtension || "webm",
        friendly_name: name,
        size
      }
    });

    if (isRecruiter) {
      yield putRequestWithToken({
        url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/invalidate/`,
        token,
        payload: {}
      });
    }

    const { data: { url = "", fields = {} } = {} } = yield getRequestWithToken({
      url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/upload-info/`,
      token
    });

    if (url) {
      cancelTokenSource = axios.CancelToken.source();
      const responseUpload = yield uploadRequestWithToken({
        fullUrl: `${url}`,
        method: "POST",
        payload: {
          ...fields,
          file: blob
        },
        onUploadProgress,
        cancelTokenSource
      });

      if (responseUpload.status === 204 || responseUpload.status === 200) {
        yield put(fetchCandidateAnswers({ jobId, userId }));
      }

      if (typeof callback === "function") yield call(callback);
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    const errorMessage = error?.message || "errors.fileDefaultError";
    logErrors({ error, context: { userId, jobId, questionId } });

    yield put(changeCandidateFileError(errorMessage));
  } finally {
    cancelTokenSource = null;
  }
}

export function* updateCandidateFileWorker({
  payload: {
    jobId = "",
    userId = "",
    questionId = "",
    mediaExtension,
    name,
    size,
    blob,
    successCallback,
    errorCallback,
    onUploadProgress
  }
}) {
  try {
    yield put(updateCandidateFileError(""));

    if (blob && (!blob.size || blob.size <= 0)) {
      throw new TypeError("errors.fileDefaultError");
    }

    const token = yield select(getToken);

    const response = yield putRequestWithToken({
      url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/`,
      token,
      payload: {
        media_extension: mediaExtension || "webm",
        friendly_name: name,
        size
      }
    });

    yield putRequestWithToken({
      url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/invalidate/`,
      token,
      payload: {}
    });

    const { data: { url = "", fields = {} } = {} } = yield getRequestWithToken({
      url: `/api/job/${jobId}/interviewer/${userId}/question/${questionId}/upload-info/`,
      token
    });

    if (url) {
      cancelTokenSource = axios.CancelToken.source();
      const responseUpload = yield uploadRequestWithToken({
        fullUrl: `${url}`,
        method: "POST",
        payload: {
          ...fields,
          file: blob
        },
        onUploadProgress,
        cancelTokenSource
      });

      if ([200, 201, 204].includes(responseUpload?.status) && typeof successCallback === "function") {
        yield call(successCallback, { payload: response?.data });
      }

      if (![200, 201, 204].includes(responseUpload?.status) && typeof errorCallback === "function") {
        yield put(updateCandidateFileError(responseUpload?.data?.error || "errors.fileDefaultError"));
      }
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    const errorMessage = error?.message || "errors.fileDefaultError";
    logErrors({ error, context: { userId, jobId, questionId } });

    yield put(updateCandidateFileError(errorMessage));
  } finally {
    cancelTokenSource = null;
  }
}

export function* updateCandidateHistoryWorker({
  payload: { userId = "", actionType = "" }
}) {
  try {
    yield put(
      isCandidateLoading({
        isLoading: true,
        userId
      })
    );

    const token = yield select(getCandidateToken);

    const response = yield postRequestWithToken({
      url: `/api/candidates/${userId}/actions/`,
      token,
      payload: {
        action_type: actionType
      }
    });

    yield put(updateCandidateHistoryResponse(response?.data));
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId } });
    console.log("[updateHistoryError]", error);
    yield put(updateCandidateHistoryError("errors.updateHistory"));
  } finally {
    yield put(
      isCandidateLoading({
        isLoading: false,
        userId
      })
    );
  }
}

export function* fetchCandidatesStatsWorker({
  payload: { id = "", dateRange = "" } = {}
}) {
  try {
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      token,
      url: `/api/job/stats/candidates/?company_key=${id}&${dateRange}`
    });

    yield put(fetchCandidatesStatsResponse(response?.data));
  } catch (error) {
    console.log("Could not get company data for chart", error);

    logErrors({ error, payload: { id, dateRange }, context: { userId: id } });
    yield put(fetchCandidatesStatsError("errors.companyChart"));
  }
}

export function* retakeAnswerWorker({
  payload: { userId, jobId, questionId }
}) {
  try {
    if (!questionId) {
      return history.push("/page-not-found");
    }

    const token = yield select(getCandidateToken);

    const { data, status } = yield postRequestWithToken({
      token,
      url: `/api/candidates/${userId}/job/${jobId}/question/${questionId}/attempt_video/`
    });

    if (status === 200) {
      yield put(
        videoAttemptResponse({
          attempt_number: data.attempt_number,
          questionId,
          userId
        })
      );
    }
  } catch (error) {
    console.log("Could not retake answer", error);

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId, jobId, questionId } });

    if (error?.response?.status === 400) {
      yield put(
        videoAttemptResponse({
          attempt_number: 100, // set it to high number to disable retake button
          questionId,
          userId
        })
      );
    }
    // change error message
    // yield put(retakeAnswerError({ userId, success: "errors.companyChart" }));
  }
}

export function* clearIsLoadingWorker() {
  try {
    if (cancelTokenSource) {
      yield call([cancelTokenSource, cancelTokenSource.cancel]);
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.error("Cancel request error:", e);
    logErrors({ error: e });
  }
}

export function* processAnswersWorker({ payload: { userId, token } = {} }) {
  try {
    yield call(postRequestWithToken, {
      url: `/api/candidates/${userId}/process-answers/`,
      token
    });
  } catch (error) {
    console.log("Process answers error", error);

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId } });
  }
}

export function* downloadAllWorker({ payload }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getRequestWithToken, {
      url: `/api/job/candidate/${payload}/download/`,
      token
    });
    yield put(downloadAllResponse(response?.data?.document));
  } catch (error) {
    console.log("[downloadAllError]", error);
    logErrors({ error, payload, context: { userId: payload } });
    yield put(downloadAllError(error));
  }
}

export function* downloadAndDeleteLinkWorker({
  payload: { candidateId, link, deleteUrl = `/api/job/candidate/${candidateId}/download/`, reportKey = "" }
}) {
  try {
    const response = yield getRequest({
      fullUrl: link,
      responseType: "arraybuffer"
    });

    const contentDisposition =
      response?.headers?.["content-disposition"].split("=") || [];
    const { pathname } = new URL(link);
    const fileName =
      contentDisposition.length > 1
        ? contentDisposition[1]
        : pathname.substring(pathname.lastIndexOf("/") + 1);
    const url = window.URL.createObjectURL(new Blob([response?.data]));
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName || (reportKey ? "candidate.csv" : "candidate.zip");
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }

    const token = yield select(getToken);
    yield call(deleteRequestWithToken, {
      url: deleteUrl,
      token
    });

    if (candidateId) yield put(downloadAndDeleteLinkResponse(candidateId));

    if (reportKey) {
      yield put(saveCandidatesExportLink({ link: "" }));
      yield put(updateIsOpenVideoArchives(false));
    }
  } catch (error) {
    logErrors({ error, payload: { candidateId, link }, context: { userId: candidateId } });
    if (candidateId) yield put(downloadAndDeleteLinkError(candidateId));
    if (reportKey) yield put(downloadAndDeleteLinkFailed());
  }
}

export function* fetchHintsAndTipsWorker({ payload: { userId } }) {
  try {
    const response = yield call(getRequest, {
      url: "/api/common/hints-and-tips/"
    });
    yield put(fetchHintsAndTipsResponse(response?.data));
  } catch (error) {
    console.log("[fetchHintsAndTipsError]", error);
    yield put(fetchHintsAndTipsError(error));
    yield call(handleCandidateError, error, { userId });
  }
}

function* getIdvSessionWorker({
  payload: { questionKey, successUrl, userId, successCallback = null } = {}
}) {
  try {
    yield put(getIdvSessionLoading(true));
    const token = yield select(getCandidateToken);

    const response = yield call(postRequestWithToken, {
      url: `/api/idv/question/${questionKey}/session/`,
      token,
      payload: {
        success_url: `${window.location.origin}${successUrl}`
      }
    });

    if (response.status === 200) {
      yield put(setIdvSession(response?.data));

      if (typeof successCallback === "function") yield call(successCallback, { response: response?.data });
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if ([403].includes(error?.response?.status) ||
    error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    if ([404, 401]?.includes(error?.response?.status)) {
      yield put(setCurrentCandidateId(""));
      return history.push("/page-not-found");
    }

    yield put(getIdvSessionError(error?.message));
    logErrors({
      error,
      payload: {
        questionKey,
        successUrl
      },
      context: {
        userId,
        questionId: questionKey
      }
    });
  } finally {
    yield put(getIdvSessionLoading(false));
  }
}

function* saveShareCodeWorker({
  payload: { shareCode, onUploadProgress, callback, userId } = {}
}) {
  try {
    const token = yield select(getCandidateToken);
    let payload = { share_code_skipped: true };

    if (!isNull(shareCode) || !isEmpty(shareCode)) {
      payload = {
        share_code: shareCode,
        share_code_skipped: false
      };
    }

    const response = yield patchRequestWithToken({
      url: "/api/idv/share-code/provide/",
      token,
      payload,
      onUploadProgress
    });

    yield put(saveShareCodeResponse(response?.data));
    if (typeof callback === "function") yield call(callback);
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId } });
    yield put(saveShareCodeError(error?.message));
    yield put(
      fetchCandidateJobInfoError({
        userId,
        error: "errors.shareCodeError"
      })
    );
  }
}

function* updateIdvSkippedWorker({ payload: { userId } = {} }) {
  try {
    const token = yield select(getCandidateToken);

    yield putRequestWithToken({
      url: `/api/candidates/${userId}/idv-skipped/`,
      token,
      payload: {}
    });
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId } });
    yield put(updateIdvSkippedError(error?.message));
  }
}

function* getIdvCheckDataWorker({ payload: { userId } = {} }) {
  try {
    yield put(getIdvCheckDataLoading(true));
    const token = yield select(getCandidateToken);

    const response = yield call(getRequestWithToken, {
      url: `/api/candidates/${userId}/idv-data/`,
      token
    });

    if (response.status === 200) {
      yield put(getIdvCheckDataResponse(response?.data));
    }
  } catch (error) {
    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_CLOSED) {
      return history.push("/interview-closed");
    }

    if (error?.response?.data?.error === CANDIDATE_INTERVIEW_SUBMITTED) {
      return history.push(`/candidate/interview-submitted/${userId}`);
    }

    logErrors({ error, context: { userId } });
    yield put(getIdvCheckDataError(error?.message));
  } finally {
    yield put(getIdvCheckDataLoading(false));
  }
}

export function* createCandidateWatcher() {
  yield takeEvery(fetchCandidateInfo, fetchCandidateInfoWorker);
  yield takeEvery(
    fetchTransferredCandidateInfo,
    fetchTransferredCandidateInfoWorker
  );
  yield takeEvery(updateCandidateInfo, updateCandidateInfoWorker);
  yield takeEvery(updateCandidateLang, updateCandidateLangWorker);
  yield takeEvery(fetchQuestionsAnswers, fetchQuestionsAnswersWorker);
  yield takeEvery(fetchCandidatesStats, fetchCandidatesStatsWorker);
  yield takeEvery(updateCandidateHistory, updateCandidateHistoryWorker);
  yield takeEvery(saveAnswer, saveAnswerWorker);
  yield takeLatest(saveOnAmazonS3, saveOnAmazonS3Worker);
  yield takeLatest(saveBlobOnAmazonS3, saveBlobOnAmazonS3Worker);
  yield takeLatest(changeCandidateFile, changeCandidateFileWorker);
  yield takeLatest(updateCandidateFile, updateCandidateFileWorker);
  yield takeLatest(retakeAnswer, retakeAnswerWorker);
  yield takeLatest(clearIsLoading, clearIsLoadingWorker);
  yield takeLatest(processAnswers, processAnswersWorker);
  yield takeLatest(downloadAll, downloadAllWorker);
  yield takeLatest(downloadAndDeleteLink, downloadAndDeleteLinkWorker);
  yield takeLatest(fetchHintsAndTips, fetchHintsAndTipsWorker);
  yield takeEvery(getIdvSession, getIdvSessionWorker);
  yield takeEvery(saveShareCode, saveShareCodeWorker);
  yield takeEvery(getIdvCheckData, getIdvCheckDataWorker);
  yield takeEvery(updateIdvSkipped, updateIdvSkippedWorker);
  yield takeEvery(initQuestion, initQuestionWorker);
  yield takeEvery(fetchAllSavedAnswers, fetchAllCandidateSavedAnswersWorker);
  yield takeEvery(saveDeviceInfo, saveDeviceInfoWorker);
}

export default createCandidateWatcher;
