/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import useWindowResize from "hooks/useWindowResize.ts";
import React from "react";
import { Category } from "store/useTemplateJobStore.ts";

interface JobsTemplateCategoriesProps {
  categories: Category[];
  onCategorySelect: (category: string) => void;
  selectedCategory: string;
}

const JobsTemplateCategories: React.FC<JobsTemplateCategoriesProps> = ({
  categories,
  onCategorySelect,
  selectedCategory
}) => {
  const { isTabletAndDesktopScreen } = useWindowResize();
  return (
    <div className="pr-10 w-full md:w-fit">
      <h2 className="text-sm text-main-link mb-4">Categories</h2>
      <div className="relative w-full">
        <div className="md:w-full overflow-x-auto">
          <div
            className="flex gap-3 min-w-min pb-2"
            style={{
              flexDirection: isTabletAndDesktopScreen ? "column" : "row"
            }}
          >
            {categories.map(category => (
              <div
                key={category.key}
                onClick={() => {
                  if (selectedCategory !== category.key) {
                    return onCategorySelect(category.key);
                  }
                  return onCategorySelect("");
                }}
                className={`text-left text-sm font-medium text-main-link cursor-pointer flex-none ${
                selectedCategory === category.key
                  ? "border-main-purple border-b w-fit"
                  : "opacity-50 hover:opacity-75 transition-opacity"
              }`}
              >
                {category.name}
              </div>
            ))}
          </div>
        </div>
        <div className="absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-white to-transparent pointer-events-none md:hidden" />
      </div>
    </div>
  );
};
export default React.memo(JobsTemplateCategories);
