/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
import React, { useEffect } from "react";
import postscribe from "postscribe";
import crypto from "crypto";
import { withRouter } from "react-router-dom";
import { getCurrentUser } from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { connect } from "react-redux";

const PrivateChat = ({
  location: { pathname },
  currentUser: { plan_name: planName, name, customer_id, role },
  profileInfo: { full_name: fullName, email, phone, agency_name: agency },
  errorMessage = ""
}) => {
  useEffect(() => {
    postscribe("#beacon", `<script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script>
    <script type="text/javascript">window.Beacon('init', 'dd53d7a3-2743-4392-a24f-acdec9f1b7bc')</script>`);

    return () => {
      if (window.Beacon) {
        console.log("Destroying beacon...");
        window.Beacon("once", "ready", () => window.Beacon && window.Beacon("destroy"));
        window.Beacon("destroy");
      }
    };
  }, []);

  useEffect(() => {
    if (!email) return;

    const signature = crypto
      .createHmac("sha256", "9m0IPqSeWpVEaiI6FYw/pDI1y0fXsA4IcfZrrp7b2Jo=")
      .update(email)
      .digest("hex");

    const identify = {
      plan: planName,
      company: name,
      name: fullName,
      email,
      phone,
      "Stripe-Customer-URL": `${process.env.REACT_APP_STRIPE_URL}/${customer_id}`,
      "Agency-Role": role,
      "Agency-Name": `${process.env.REACT_APP_BACKEND_URL}/admin/agencies/agency/?q=${agency}`,
      "Error-Message": errorMessage
    };

    if (window.Beacon) {
      window.Beacon("identify", { ...identify, signature });
      window.Beacon("info");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, planName, name, phone, fullName]);

  useEffect(() => {
    if (window.profitwell) window.profitwell("start", { user_id: customer_id });
  }, [customer_id]);

  useEffect(() => {
    if (!window.Beacon) return;

    window.Beacon("event", {
      type: "page-viewed",
      url: document.location.href,
      title: document.title
    });

    window.Beacon("suggest");
  }, [pathname]);

  return (
    <div id="beacon" />
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  profileInfo: getProfileInfo(state)
});

export default withRouter(connect(mapStateToProps)(PrivateChat));
