import React from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import ReactNotification from "react-notifications-component";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "react-error-boundary";
import { Auth0Provider } from "@auth0/auth0-react";

import RouterContainer from "containers/Router";
import ModalsContainer from "containers/Modals";
import Head from "components/Head";

import "./i18n";
import "./tailwind.css";
import "./assets/styles/main.scss";
import "react-phone-number-input/style.css";

import ErrorFallback from "components/Common/Error/ErrorFallback/ErrorFallback";
import { logErrors } from "mixins/helperLogging";
import Spinner from "components/Common/Spinner";
import HydrationGuard from "components/Common/LoadingWrapper/LoadingWrapper.tsx";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { history, store, persistor } from "./store";

const App = () => (

  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={(error, info) => {
      console.error({ error, info });
      logErrors({ error, context: { info, type: "all" } });
    }}
  >
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY}
      useEnterprise
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "head",
        // eslint-disable-next-line no-underscore-dangle
        nonce: window.__recaptcha_nonce,
        crossOrigin: "anonymous",
        id: "recaptcha-script"
      }}
      container={{
        element: "google-recaptcha-enterprise-container",
        parameters: {
          badge: "bottomright",
          size: "invisible"
        }
      }}
      refreshReCaptcha={false}
      language="en"
      onLoad={() => {
        console.log("reCAPTCHA loaded successfully");
      }}
      onError={() => {
        console.error("reCAPTCHA failed to load");
      }}
    >
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE
        }}
        debug={!["production"]?.includes(process.env.REACT_APP_ENV)}
        advancedOptions={{ logging: !["production"]?.includes(process.env.REACT_APP_ENV) }}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <PersistGate
              loading={(
                <div className="loader">
                  <Spinner />
                </div>
              )}
              persistor={persistor}
            >
              <ConnectedRouter history={history}>
                <HydrationGuard>
                  <ReactNotification />
                  <Head />
                  <Route path="/" component={RouterContainer} />
                  <ModalsContainer />
                </HydrationGuard>
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        </GoogleOAuthProvider>
      </Auth0Provider>
    </GoogleReCaptchaProvider>
  </ErrorBoundary>
);

export default App;
