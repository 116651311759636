/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getCandidateAnswers, getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { ChevronLeft, ChevronRight, TimerIcon } from "mixins/svgIcons";
import { spentTimeString } from "mixins/helperVideoRecording";
import TooltipInfo from "components/Common/Tooltip/Info";
import { store } from "store";
import { setCurrentQuestionAnswer, setSidebarOpen } from "store/modules/jobs/actions";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import useWindowResize from "hooks/useWindowResize.ts";
import { isSidebarEnabled } from "mixins/helpers";
import CandidateWidget from "./CandidateWidget";
import CandidateAnswerThumbnailSection from "./CandidateAnswerThumbnailSection";
import CandidateAnswerResponse from "./CandidateAnswerResponse/CandidateAnswerResponse";
import CandidateSidebar from "./CandidateSidebar/CandidateSidebar";
import CandidateAnswerTitle from "./CandidateAnswerTitle";

const CandidateAnswerSection = ({
  t,
  handleGoToScorecard,
  currentQuestionAnswer: {
    key: answerKey,
    question,
    spent_time: spentTime
  } = {},
  currentQuestionAnswer,
  candidateAnswers,
  realtimeAnswers,
  isJobCandidateLoading,
  isExpired = false,
  jobId,
  userId,
  isSidebarOpen
}) => {
  const [autoPlay, setAutoPlay] = useState(false);

  const {
    isMobileScreen,
    isTabletAndDesktopScreen,
    isMobileAndTabletScreen
  } = useWindowResize();

  const nextAnswer = candidateAnswers?.find(
    answer => answer?.question?.order === question?.order + 1
  );

  const previousAnswer = candidateAnswers?.find(
    answer => answer?.question?.order === question?.order - 1
  );

  const handleNextQuestion = () => {
    if (nextAnswer) store.dispatch(setCurrentQuestionAnswer(nextAnswer));
  };

  const handlePreviousQuestion = () => {
    if (previousAnswer) store.dispatch(setCurrentQuestionAnswer(previousAnswer));
  };

  useEffect(() => {
    let isMounted = true;

    if (jobId && isMounted) {
      isMounted = false;
      store.dispatch(setCurrentQuestionAnswer(candidateAnswers[0]));
    }

    return () => {
      isMounted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateAnswers?.length, jobId]);

  return (
    <div className="candidateanswer__section">
      <div className="candidateanswer__section__header container">
        <div className={`candidateanswer__section__header-title__wrapper flex-1 ${isExpired && !isJobCandidateLoading ? "expired" : ""}`}>
          <div className="flex-1 flex flex-col" style={{ paddingRight: 24 }}>
            <div className="candidateanswer__section__header-title__navigation">
              <ChevronLeft
                onClick={handlePreviousQuestion}
                disabled={!previousAnswer}
                fill={!previousAnswer ? "#D8D8D8" : "#5a2af1"}
                style={previousAnswer ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                width="20"
                height="20"
              />
              <SkeletonWrapper
                skeletonProps={{
                  count: 1,
                  height: 16,
                  width: 120,
                  enableAnimation: true
                }}
                isLoading={isJobCandidateLoading}
              >
                <span>{t("job.candidate.overview.header.title", { current: question?.order || 0, total: candidateAnswers?.length })}</span>
              </SkeletonWrapper>
              <ChevronRight
                onClick={handleNextQuestion}
                disabled={!nextAnswer}
                fill={!nextAnswer ? "#D8D8D8" : "#5a2af1"}
                style={nextAnswer ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                width="20"
                height="20"
              />
            </div>
            <CandidateAnswerTitle
              question={question}
              isJobCandidateLoading={isJobCandidateLoading}
              answerKey={answerKey}
              key={answerKey}
            />
          </div>
          <div className="candidateanswer__section__header-info">
            <div className="candidateanswer__section__header__thinking-time__wrapper">
              <SkeletonWrapper
                skeletonProps={{
                  count: 1,
                  height: 16,
                  width: 180,
                  enableAnimation: true
                }}
                skeletonWrapperProps={{
                  style: {
                    marginBottom: 15,
                    lineHeight: 2,
                    marginTop: isMobileAndTabletScreen ? 15 : 0
                  }
                }}
                isLoading={isJobCandidateLoading}
              >
                <TooltipInfo
                  position="bottom"
                  message={t("candidate.common.tooltipThinking")}
                  disabled={!question?.thinking_time}
                >
                  <p
                    className={`candidateanswer__section__header__thinking-time n-font-medium-weight n-black-80 ${
                      question?.thinking_time < spentTime ? "amber" : "amber"
                    }
                    ${ !question?.thinking_time ? "hidden" : "visible"}
                  `}
                  >
                    <TimerIcon fill="#FFB21E" />
                    <span>
                      {`${spentTimeString(spentTime)} / ${spentTimeString(
                    question?.thinking_time
                      )}`}
                    </span>
                  </p>
                </TooltipInfo>
              </SkeletonWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="candidateanswer__section__content-wrapper">
        <div className="candidateanswer__section__content">
          <CandidateAnswerResponse
            setOpenSidebar={val => {
              store.dispatch(setSidebarOpen(val));
            }}
            openSidebar={isSidebarOpen}
            handleGoToScorecard={handleGoToScorecard}
            isExpired={isExpired}
            jobId={jobId}
            userId={userId}
            realtimeAnswers={realtimeAnswers}
            autoPlay={autoPlay}
            setAutoPlay={setAutoPlay}
          />
          {
            isSidebarEnabled && isTabletAndDesktopScreen ? (
              <CandidateWidget
                setOpenSidebar={val => store.dispatch(setSidebarOpen(val))}
                activeSidebar={isSidebarOpen}
                currentQuestionAnswer={currentQuestionAnswer}
              />
            ) : null
          }
        </div>

        {
          isSidebarEnabled && isTabletAndDesktopScreen ? (
            <div className={`candidateanswer__section__content__sidebar relative ${isSidebarOpen ? "open" : ""}`}>
              <CandidateSidebar />
            </div>
          ) : null
        }
      </div>

      <CandidateAnswerThumbnailSection
        isExpired={isExpired}
        userId={userId}
        setAutoPlay={setAutoPlay}
      />

      {
        isSidebarEnabled && isMobileScreen ? (
          <>
            <CandidateWidget
              setOpenSidebar={val => store.dispatch(setSidebarOpen(val))}
              activeSidebar={isSidebarOpen}
              currentQuestionAnswer={currentQuestionAnswer}
            />

            {
              isSidebarOpen ? (
                <div className="candidateanswer__section__content__sidebar open relative pt-4">
                  <CandidateSidebar />
                </div>
              ) : null
            }
          </>
        ) : null
      }
    </div>
  );
};

CandidateAnswerSection.defaultProps = {
  t: undefined,
  handleGoToScorecard: () => {},
  currentQuestionAnswer: {},
  candidateAnswers: [],
  isExpired: false,
  isJobCandidateLoading: true,
  realtimeAnswers: [],
  jobId: "",
  userId: "",
  isSidebarOpen: null
};

CandidateAnswerSection.propTypes = {
  t: PropTypes.func,
  handleGoToScorecard: PropTypes.func,
  currentQuestionAnswer: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string,
      text: PropTypes.string
    })
  }),
  candidateAnswers: PropTypes.shape([]),
  isExpired: PropTypes.bool,
  isJobCandidateLoading: PropTypes.bool,
  realtimeAnswers: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.shape([])]),
  jobId: PropTypes.string,
  userId: PropTypes.string,
  isSidebarOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  candidateAnswers: getCandidateAnswers(state),
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isSidebarOpen: state?.jobs?.isSidebarOpen
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerSection));
