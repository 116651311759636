/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { getCandidateAnswers, getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import Chevron from "assets/images/candidate/Chevron.png";
import classNames from "classnames";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import AnswerListIcon from "assets/images/video/AnswerListIcon";
import useWindowResize from "hooks/useWindowResize.ts";
import ChevronIcon from "assets/images/video/ChevronIcon";
import { isMobile } from "react-device-detect";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import StartFirebase from "configs/firebase";
import { ANSWER_STATUS } from "configs/jobs/constants";
import CandidateAnswerThumbnailItem from "./CandidateThumbnail/CandidateThumbnailItem";

const CandidateAnswerThumbnailSection = ({
  candidateAnswers,
  currentQuestionAnswer,
  isJobCandidateLoading,
  isExpired = false,
  userId,
  setAutoPlay
}) => {
  const observer = useRef();
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
  const visibleIndexesRef = useRef(new Set());
  const [isLastIndexVisible, setisLastIndexVisible] = useState(false);
  const [isFirstIndexVisible, setIsFirstIndexVisible] = useState(false);
  const containerRef = useRef();
  const thumbnailWidth = 216;
  const [isCollapsed, setIsCollapsed] = useState(!!isMobile);
  const [db, setDB] = useState();
  const { isMobileAndTabletScreen } = useWindowResize();

  const [snapshot] = useObject(
    db && userId ? ref(db, `Candidates/${userId}`) : null
  );

  const realtimeAnswers = snapshot?.val()?.answers;


  const getDatabase = useCallback(async () => {
    const database = await StartFirebase();
    setDB(database);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;

      getDatabase();
    }

    return () => {
      isMounted = true;
    };
  }, []);


  const displayedItems = useMemo(() => {
    if (isCollapsed) {
      const currentSelectedIndex = candidateAnswers?.findIndex(candidateAnswer =>
        candidateAnswer.key ===
        currentQuestionAnswer?.key);

      const nextAnswer = candidateAnswers[currentSelectedIndex + 1];
      return nextAnswer ? [nextAnswer] : [];
    }
    return candidateAnswers;
  }, [candidateAnswers, isCollapsed, currentQuestionAnswer]);


  function smoothScroll(element, target, duration) {
    const start = isMobileAndTabletScreen ? element.scrollTop : element.scrollLeft;
    const maxScroll = isMobileAndTabletScreen ? (element.scrollHeight - element.clientHeight)
      : (element.scrollWidth - element.clientWidth);
    const targetAdjusted = Math.min(target, maxScroll);
    const change = targetAdjusted - start;
    const startTime = performance.now();
    let now; let elapsed; let
      time;

    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    function animateScroll() {
      now = performance.now();
      elapsed = (now - startTime) / 1000;
      time = (elapsed / duration);

      if (isMobileAndTabletScreen) {
        element.scrollTop = start + change * easeInOutQuad(time);
      } else {
        element.scrollLeft = start + change * easeInOutQuad(time);
      }

      if (time < 1) window.requestAnimationFrame(animateScroll);
    }

    animateScroll();
  }


  function smoothScrollToId(id, duration) {
    const element = document.getElementById(id);
    if (!element) {
      console.error(`Element with id ${id} not found`);
      return;
    }

    const container = element.parentElement;
    const target = isMobileAndTabletScreen ? element.offsetTop : element.offsetLeft;
    smoothScroll(container, target, duration);
  }

  useEffect(() => {
    if (currentQuestionAnswer) {
      const index = candidateAnswers.findIndex(answer => answer.key === currentQuestionAnswer.key);
      if (index !== -1) {
        setTimeout(() => {
          smoothScrollToId(isMobileAndTabletScreen ? index + 1 : index, 0.5);
        }, 500);
      }
    }
  }, [currentQuestionAnswer]);


  useEffect(() => {
    observer.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const index = parseInt(entry.target.id, 10);

          // Get the bounding rectangles
          const elementRect = entry.boundingClientRect;
          const containerRect = containerRef.current.getBoundingClientRect();

          // Add a small buffer (e.g., 2 pixels) to handle floating point precision
          const buffer = 100;

          const isPartiallyHiddenLeft = elementRect.left < containerRect.left - buffer;
          const isPartiallyHiddenRight = elementRect.right > containerRect.right + buffer;

          if (index === candidateAnswers.length - 1) {
            const isCompletelyVisible =
              elementRect.right <= containerRect.right + buffer &&
              elementRect.left >= containerRect.left - buffer;
            setisLastIndexVisible(isCompletelyVisible);
          }

          if (index === 0) {
            const isFullyVisible = !isPartiallyHiddenLeft && !isPartiallyHiddenRight;
            setIsFirstIndexVisible(isFullyVisible);
          }

          if (entry.isIntersecting) {
            visibleIndexesRef.current.add(index);
          } else {
            visibleIndexesRef.current.delete(index);
          }
        });

        const visibleIndexes = Array.from(visibleIndexesRef.current);
        if (visibleIndexes.length > 0) {
          setFirstVisibleIndex(Math.min(...visibleIndexes));
        }
      },
      {
        root: containerRef.current,
        rootMargin: "0px",
        threshold: [0, 0.1, 0.5, 1.0]
      }
    );

    if (containerRef.current) {
      const items = containerRef.current.getElementsByTagName("li");
      Array.from(items).forEach(item => {
        observer.current.observe(item);
      });
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [candidateAnswers.length]);

  return (
    <div
      className="container"
      style={{
        position: "relative"
      }}
    >
      <div
        className={`candidateanswer__section__thumbnail  ${isExpired ? "expired" : ""}`}
        id="candidateanswer__section__thumbnail"
        style={{
          marginTop: 20
        }}
        ref={containerRef}
      >
        {
          !isMobileAndTabletScreen
            ? (
              <div
                className={classNames(
                  "candidateanswer__section__thumbnail-chevron left",
                  isFirstIndexVisible || isExpired ? "" : "visible"
                )}
                onClick={() => {
                // Scroll to the new position with animation
                  const element = document.getElementById(0);

                  const container = element.parentElement;
                  const target = isMobileAndTabletScreen
                    ? element.offsetTop : element.offsetLeft - 200;
                  smoothScroll(container, target, 0.5);
                }}
              >
                <img src={Chevron} alt="next" />
              </div>
            ) : null
        }
        {displayedItems?.map((answer, index) => (
          <li
            key={index}
            id={index.toString()}
            ref={element => {
              if (element && observer.current) {
                observer.current.observe(element);
              }
            }}
            style={{
              padding: 0
            }}
          >
            <SkeletonWrapper
              skeletonProps={{
                width: 200,
                height: 100,
                enableAnimation: isJobCandidateLoading
              }}
              isLoading={isJobCandidateLoading}
            >
              {
                !isExpired ? (
                  <CandidateAnswerThumbnailItem
                    answer={answer}
                    key={answer.key}
                    index={index}
                    userId={userId}
                    setAutoPlay={setAutoPlay}
                    isProcessed={realtimeAnswers?.[answer.key]?.status === ANSWER_STATUS.processed}
                  />
                ) : null
              }
            </SkeletonWrapper>
          </li>
        ))}
        {
          !isMobileAndTabletScreen && (
            <>
              <div
                className={classNames(
                  "candidateanswer__section__thumbnail-chevron",
                  isLastIndexVisible || isExpired ? "" : "visible"
                )}
                onClick={() => {
                  const containerWidth = containerRef.current?.clientWidth;
                  const thumbnailItems = Math.floor(containerWidth / thumbnailWidth);
                  const scrollPosition = (firstVisibleIndex + thumbnailItems) * thumbnailWidth;

                  // Scroll to the new position with animation
                  smoothScroll(containerRef.current, scrollPosition, 0.5);
                }}
              >
                <img src={Chevron} alt="next" />
              </div>
            </>
          )
        }

      </div>
      {
        isMobileAndTabletScreen && (
          <button
            className="button__without-styles w-full"
            type="button"
            onClick={() => {
              setIsCollapsed(prev => !prev);
            }}
            aria-label={!isCollapsed ? "Up Next" : "View All Responses"}
          >
            <div className="up-next-button">
              <div className="flex gap-2">
                <div>
                  <AnswerListIcon />
                  <span>
                    {
                      !isCollapsed ? "Up Next" : "View All Responses"
                    }
                  </span>
                </div>
                <ChevronIcon isCollapsed={isCollapsed} />
              </div>
            </div>
          </button>
        )
      }
    </div>
  );
};

CandidateAnswerThumbnailSection.defaultProps = {
  candidateAnswers: [],
  currentQuestionAnswer: {},
  isExpired: false,
  isJobCandidateLoading: false
};

CandidateAnswerThumbnailSection.propTypes = {
  candidateAnswers: PropTypes.arrayOf(PropTypes.shape({})),
  currentQuestionAnswer: PropTypes.shape({
    key: PropTypes.string,
    mediaStatus: PropTypes.string
  }),
  isExpired: PropTypes.bool,
  isJobCandidateLoading: PropTypes.bool,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  candidateAnswers: getCandidateAnswers(state),
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  isJobCandidateLoading: getIsJobCandidateLoading(state)
});

export default connect(mapStateToProps)(CandidateAnswerThumbnailSection);
