import React, { useCallback, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "mixins/svgIcons";
import { getTotalGroups, STEPS_PER_GROUP } from "utils/stepper.ts";
import { Question } from "types/store/candidateAnswer.ts";

type StepperMobileProps = {
  questions: Question[];
  renderStep: (question: Question, index: number, isMobile: boolean) => React.ReactNode;
  currentStep: number;
};

const StepperMobile = ({
  questions,
  renderStep,
  currentStep
}: StepperMobileProps) => {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const totalSteps = questions.length;
  const totalGroups = getTotalGroups(totalSteps);

  useEffect(() => {
    const groupIndex = Math.floor((currentStep - 1) / 5);
    setCurrentGroupIndex(groupIndex);
  }, [currentStep]);

  const handleGroupChange = useCallback((newIndex: number) => {
    if (newIndex >= 0 && newIndex < totalGroups) {
      setCurrentGroupIndex(newIndex);
      // eslint-disable-next-line no-unused-expressions
      navigator?.vibrate?.(20);
    }
  }, [totalGroups]);

  return (
    <div>
      <div className="flex items-center">
        {currentGroupIndex > 0 && (
          <button
            type="button"
            onClick={() => handleGroupChange(currentGroupIndex - 1)}
            className="p-2 bg-white/80 rounded-lg shadow-md"
          >
            <ChevronLeft svgIconClass="w-5 h-5 text-gray-600" />
          </button>
        )}

        <div className="flex-1 px-4 py-4">
          <div className="flex justify-between items-center gap-2">
            {questions
              .slice(
                currentGroupIndex * STEPS_PER_GROUP,
                (currentGroupIndex + 1) * STEPS_PER_GROUP
              )
              .map((question, localIndex) => {
                const globalIndex = currentGroupIndex * STEPS_PER_GROUP + localIndex;
                return renderStep(question, globalIndex, true);
              })}
          </div>
        </div>

        {currentGroupIndex < totalGroups - 1 && (
          <button
            type="button"
            onClick={() => handleGroupChange(currentGroupIndex + 1)}
            className="p-2 bg-white/80 rounded-lg shadow-md"
          >
            <ChevronRight svgIconClass="w-5 h-5 text-gray-600" />
          </button>
        )}
      </div>

      {
        totalSteps > 5 && (
          <div className="flex justify-center gap-1 pb-2">
            {Array.from({ length: totalGroups }).map((_, index) => (
              <button
                key={`indicator-dot-${String(index)}`}
                type="button"
                onClick={() => handleGroupChange(index)}
                className={`
              w-2 h-2 rounded-full transition-all duration-300
              ${index === currentGroupIndex
                  ? "bg-tertiary-green w-4"
                  : "bg-gray-300 hover:bg-gray-400"}
            `}
              />
            ))}
          </div>
        )
      }
    </div>
  );
};

export default StepperMobile;
