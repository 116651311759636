import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Edit, TrashBin, Accept, Cancel } from "mixins/svgIcons";
import { checkPhoneValidity, checkEmailValidity, checkValidity } from "mixins/helperJobInvites";
import Table from "components/Common/Table";

import { Error, Success, ErrorMessage } from "./svgIcons";
import "./styles.scss";

const cellWithValidation = (isValid, data) => {
  const error = !isValid ? "with-error" : null;

  return <span className={`table-cell overflow-hidden overflow-ellipsis ${error}`}>{data || "—"}</span>;
};

const getRecordSTring = (count, t) => `${count} ${count > 1 ? t("job.invite.table.records") : t("job.invite.table.record")}`;

const InviteCandidates = ({
  t,
  jobInvitesData,
  jobInvitesParseData,
  updateJobInvite,
  setModal,
  setJobInvites,
  errorCounter,
  clearJobInvitesParseData
}) => {
  const name = useRef();
  const phone = useRef();
  const email = useRef();

  const [editing, setEditing] = useState(null);

  const handleSubmit = event => {
    updateJobInvite({
      key: event.target.value,
      name: name.current.value?.trim(),
      phone: phone.current.value?.trim(),
      email: email.current.value?.trim()
    });

    setEditing(null);
  };

  const getActionProps = (gridState, rowProps) =>
    rowProps && {
      mode: editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => setEditing(rowProps.original),
        onCancel: () => setEditing(null)
      }
    };

  const handleDelete = event => {
    setModal({
      type: "deleteInviteConfirmation",
      inviteConfirmation: { value: event.target.value }
    });
  };

  const removeNotValidInvites = () => {
    setJobInvites(jobInvitesData.filter((invite => checkValidity(invite))));
  };

  return jobInvitesData.length ? (
    <>
      {jobInvitesParseData ? (
        <div className="table__invites-success-message">
          <p className="n-font-small n-font-regular n-grey-60">
            {t("job.invite.table.importSuccessful", {
              parcedCount: getRecordSTring(jobInvitesParseData.total, t),
              importedCount: getRecordSTring(jobInvitesParseData.success, t),
              failedCount:
                getRecordSTring(jobInvitesParseData.total - jobInvitesParseData.success, t)
            })}
          </p>
          <button type="button" className="button__without-styles" onClick={clearJobInvitesParseData}>
            <Error />
          </button>
        </div>
      ) : null}
      <Table
        t={t}
        header={[
          {
            Header: t("job.invite.table.fullName"),
            accessor: "name",
            headerClassName: "first-column left",
            style: { fontWeight: 400 },
            className: "fullname-invite",
            Cell: row => {
              const isEditable = editing === row.original;
              const isValid = checkValidity(row.original);

              return isEditable ? (
                <input
                  name="name"
                  type="text"
                  ref={name}
                  className="editable__input"
                  defaultValue={row.original.name}
                />
              ) : (
                <div className="w-full flex flex-row">
                  <span>{isValid ? <Success /> : <Error />}</span>
                  {cellWithValidation(!!row.original.name, row.original.name || "—")}
                </div>
              );
            }
          },
          {
            Header: t("job.invite.table.email"),
            accessor: "email",
            style: { textAlign: "center" },
            Cell: row => {
              const isEditable = editing === row.original;
              const dataForCell = row.original.email;
              const isValid = checkEmailValidity(dataForCell);

              return isEditable ? (
                <input
                  name="email"
                  type="email"
                  ref={email}
                  className="editable__input"
                  defaultValue={row.original.email}
                />
              ) : (
                cellWithValidation(isValid, dataForCell)
              );
            }
          },
          {
            Header: t("job.invite.table.phone"),
            accessor: "phone",
            style: { textAlign: "center" },
            Cell: row => {
              const isEditable = editing === row.original;
              const dataForCell = row.original.phone;
              const isValid = checkPhoneValidity(dataForCell);

              return isEditable ? (
                <input
                  name="phone"
                  type="text"
                  ref={phone}
                  className="editable__input"
                  defaultValue={row.original.phone}
                />
              ) : (
                cellWithValidation(isValid, dataForCell)
              );
            }
          },
          {
            accessor: false,
            Cell: row => {
              const {
                mode,
                actions: { onEdit }
              } = row.columnProps.rest;
              return mode === "edit" ? (
                <button
                  value={row.original.key}
                  onClick={handleSubmit}
                  type="button"
                  className="button__without-styles"
                >
                  <Accept svgIconClass="not-clickable-icon" />
                </button>
              ) : (
                <button
                  onClick={() => onEdit(row.original.key)}
                  value={row.original.index}
                  className="button__without-styles"
                  type="button"
                >
                  <Edit svgIconClass="not-clickable-icon" />
                </button>
              );
            },
            style: { textAlign: "center", paddingLeft: 0, paddingRight: 0 },
            width: 30,
            sortable: false,
            getProps: getActionProps
          },
          {
            accessor: false,
            Cell: row => {
              const {
                mode,
                actions: { onCancel }
              } = row.columnProps.rest;

              return mode === "edit" ? (
                <button
                  onClick={onCancel}
                  type="button"
                  className="button__without-styles"
                >
                  <Cancel svgIconClass="not-clickable-icon" />
                </button>
              ) : (
                true && (
                  <button
                    onClick={handleDelete}
                    value={row.original.key}
                    className="button__without-styles"
                    type="button"
                  >
                    <TrashBin svgIconClass="not-clickable-icon" />
                  </button>
                )
              );
            },
            style: { textAlign: "center", paddingLeft: 0 },
            width: 60,
            sortable: false,
            getProps: getActionProps
          }
        ]}
        getTrProps={(state, rowInfo) => {
          if (rowInfo) {
            const isCurrentRow =
              rowInfo.original.key &&
              rowInfo.original.key === (editing && editing.key);

            return {
              style: {
                backgroundColor: isCurrentRow ? "rgba(224,224,224,0.2)" : "white",
                border: isCurrentRow ? "1px solid #B6BDC5" : "inherit"
              }
            };
          }
          return false;
        }}
        body={jobInvitesData}
      />
      {errorCounter ? (
        <p className="n-black-100 n-font-small table__invites-error-message">
          <ErrorMessage />
          {t(`onboarding.removeAll.${errorCounter === 1 ? "0" : "1"}`, { counter: errorCounter })}
          <button type="button" className="button__without-styles n-purple-100" onClick={removeNotValidInvites}>
            {t("onboarding.removeAll.2")}
          </button>
        </p>
      ) : null}
    </>
  ) : null;
};

InviteCandidates.propTypes = {
  t: PropTypes.func.isRequired,
  jobInvitesData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      full_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string
    })
  ).isRequired,
  jobInvitesParseData: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number
  }).isRequired,
  errorCounter: PropTypes.number.isRequired,
  updateJobInvite: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  clearJobInvitesParseData: PropTypes.func.isRequired,
  setJobInvites: PropTypes.func.isRequired
};

export default withTranslation()(InviteCandidates);
