import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import { ChevronRight } from "mixins/svgIcons";
import useWindowResize from "hooks/useWindowResize.ts";

const ArrowButtonRight = ({
  currentIndex,
  nextIndex,
  items,
  setSelected
}) => {
  const { isMobileAndTabletScreen } = useWindowResize();

  const prevIndex = Math.max(currentIndex - 1, 0);


  const handleKeyDown = debounce(e => {
    // Check if any input element is focused
    const { activeElement } = document;
    const isInputFocused = activeElement.tagName.toLowerCase() === "input";


    if (!items.length) return;

    // Only handle arrow keys when no input is focused or when cmd/ctrl isn't pressed
    if (!isInputFocused) {
      if (e.keyCode === 37) {
        setSelected(items[prevIndex]?.key);
      } else if (e.keyCode === 39) {
        setSelected(items[nextIndex]?.key);
      }
    }
  }, 500);

  useEffect(() => {
    const handleKeyDownWithCapture = e => {
      const { activeElement } = document;
      const isInputFocused = activeElement.tagName.toLowerCase() === "input";

      if (isInputFocused && (e.metaKey || e.ctrlKey) &&
          (e.keyCode === 37 || e.keyCode === 39)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDownWithCapture, true);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDownWithCapture, true);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <button
        type="button"
        className="button__without-styles arrow-button right"
        onClick={() => setSelected(items[nextIndex].key)}
        disabled={[-1, items.length - 1].includes(currentIndex)}
        title={currentIndex === items.length - 1 ? null : "next"}
        aria-label="Next"
      >
        <ChevronRight
          fill={[-1, items.length - 1].includes(currentIndex) ? "#595959" : "#5A2AF1"}
          height={isMobileAndTabletScreen ? "20" : "24"}
          width={isMobileAndTabletScreen ? "20" : "24"}
        />
      </button>
    </>
  );
};

ArrowButtonRight.defaultProps = {
  items: [],
  setSelected: null
};

ArrowButtonRight.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nextIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  setSelected: PropTypes.func
};

export default ArrowButtonRight;
