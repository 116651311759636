import React from "react";
import {
  FaMicrophone,
  FaVideo,
  FaCloudUploadAlt,
  FaAlignLeft,
  FaIdCard
} from "react-icons/fa";

import { Check, Close, Questionmark } from "mixins/svgIcons";

import { IoCheckbox, IoRadioButtonOnSharp } from "react-icons/io5";

export const INITIAL_DURATION = 60;

export const getDurations = isDisabled => [
  { isActive: true, value: 60 },
  { isActive: true, value: 120 },
  { isActive: true, value: 180 },
  { isActive: false, value: 240, isDisabled },
  { isActive: false, value: 300, isDisabled }
];

export const INITIAL_RETAKES = null;

export const RETAKES = [
  {
    isActive: true,
    value: 0,
    titleKey: "job.questions.retakes.total_retakes",
    shortTitle: "job.questions.retakes.short_retakes"
  },
  {
    isActive: true,
    value: 1,
    titleKey: "job.questions.retakes.total_retakes",
    shortTitle: "job.questions.retakes.short_retakes"
  },
  {
    isActive: true,
    value: 3,
    titleKey: "job.questions.retakes.total_retakes",
    shortTitle: "job.questions.retakes.short_retakes"
  },
  {
    isActive: true,
    value: 5,
    titleKey: "job.questions.retakes.total_retakes",
    shortTitle: "job.questions.retakes.short_retakes"
  },
  {
    isActive: true,
    value: null,
    titleKey: "job.questions.retakes.unlimited",
    shortTitle: "ထ"
  }
];

export const JOB_STATUSES = {
  CLOSED: "closed",
  LIVE: "live",
  DRAFT: "draft",
  EXPIRED_CLOSED: "expired_closed"
};

export const PRODUCT_FEATURES = {
  REDIRECT_URL: "redirect-url"
};

export const INITIAL_TYPE = "video";
export const VIDEO_TYPE = "video";
export const AUDIO_TYPE = "audio";
export const FILE_TYPE = "file";
export const MULTIPLE_CHOICE_TYPE = "multiple_choice";
export const CHECKBOXES_TYPE = "checkboxes";
export const TEXT_TYPE = "text";
export const EXPIRED = "expired";

export const TYPES = [
  {
    isActive: true,
    value: "video",
    titleKey: "job.questions.types.video",
    icon: "fa-video",
    reactIcon: <FaVideo />
  },
  {
    isActive: true,
    value: "audio",
    titleKey: "job.questions.types.audio",
    icon: "fa-microphone",
    reactIcon: <FaMicrophone />
  },
  {
    isActive: true,
    value: "file",
    titleKey: "job.questions.types.file",
    icon: "fa-cloud-upload-alt",
    reactIcon: <FaCloudUploadAlt />
  },
  {
    isActive: true,
    value: "text",
    titleKey: "job.questions.types.text",
    icon: "fa-align-left",
    reactIcon: <FaAlignLeft />
  },
  {
    isActive: true,
    value: "multiple_choice",
    titleKey: "job.questions.types.multiple_choice",
    icon: "fa-radio-button",
    reactIcon: <IoRadioButtonOnSharp />
  },
  {
    isActive: true,
    value: "checkboxes",
    titleKey: "job.questions.types.checkboxes",
    icon: "fa-checkboxes",
    reactIcon: <IoCheckbox />
  }
];

export const IDV_INCLUDED_TYPES = [
  {
    isActive: true,
    value: "video",
    titleKey: "job.questions.types.video",
    icon: "fa-video",
    reactIcon: <FaVideo />
  },
  {
    isActive: true,
    value: "audio",
    titleKey: "job.questions.types.audio",
    icon: "fa-microphone",
    reactIcon: <FaMicrophone />
  },
  {
    isActive: true,
    value: "file",
    titleKey: "job.questions.types.file",
    icon: "fa-cloud-upload-alt",
    reactIcon: <FaCloudUploadAlt />
  },
  {
    isActive: true,
    value: "text",
    titleKey: "job.questions.types.text",
    icon: "fa-align-left",
    reactIcon: <FaAlignLeft />
  },
  {
    isActive: true,
    value: "idv",
    titleKey: "job.questions.idv.label",
    icon: "fa-id-card",
    reactIcon: <FaIdCard />
  },
  {
    isActive: true,
    value: "multiple_choice",
    titleKey: "job.questions.types.multiple_choice",
    icon: "fa-radio-button",
    reactIcon: <IoRadioButtonOnSharp />
  },
  {
    isActive: true,
    value: "checkboxes",
    titleKey: "job.questions.types.checkboxes",
    icon: "fa-checkboxes",
    reactIcon: <IoCheckbox />
  }
];

export const ANSWER_TYPES = {
  video: {
    icon: "fa-video",
    label: "job.questions.types.video",
    value: "video",
    reactIcon: <FaVideo />
  },
  file: {
    icon: "fa-cloud-upload-alt",
    label: "job.questions.types.file",
    value: "file",
    reactIcon: <FaCloudUploadAlt />
  },
  audio: {
    icon: "fa-microphone",
    label: "job.questions.types.audio",
    value: "audio",
    reactIcon: <FaMicrophone />
  },
  text: {
    icon: "fa-align-left",
    label: "job.questions.types.text",
    value: "text",
    reactIcon: <FaAlignLeft />
  },
  idv: {
    icon: "fa-id-card",
    label: "job.questions.types.idv",
    value: "idv",
    reactIcon: <FaIdCard />
  },
  multiple_choice: {
    icon: "fa-radio-button",
    label: "job.questions.types.multiple_choice",
    value: "multiple_choice",
    reactIcon: <IoRadioButtonOnSharp />
  },
  checkboxes: {
    icon: "fa-checkboxes",
    label: "job.questions.types.checkboxes",
    value: "checkboxes",
    reactIcon: <IoCheckbox />
  }
};

export const INITIAL_LIMIT = "max_words";
export const INITIAL_MAX = 500;

export const TEXT_LIMITS = [
  { value: "max_characters", titleKey: "job.questions.textLimit.characters" },
  { value: "max_words", titleKey: "job.questions.textLimit.words" }
];

export const INITIAL_MAX_CHECKS = null;

export const INITIAL_COUNTRIES = null;

export const RTW_COUNTRIES = ["GBR", "IRL", "GB", "IE", "IRL", "IMN", "IM", "GG", "GGY", "JE", "JEY"];

export const IDV_TYPE = "idv";
export const IDV_LABEL = "Identity Verification";
export const RTW_TYPE = "rtw";
export const DBS_RTW_TYPE = "dbs_rtw";
export const DBS_TYPE = "dbs";
export const IDV_SKIPPED = "skipped";
export const IDV_OMITTED = "Omitted";
export const IDV_ABORTED = "aborted";
export const IDV_ACCEPTED = "accepted";
export const IDV_CHECK = "Digital Identity Checks";

export const IDV_TYPE_LABELS = {
  idv: "Digital identity check",
  dbs: "DBS - Identity check",
  rtw: "Right-to-work check",
  dbs_rtw: "Right-to-work check + DBS identity check"
};

export const IDV_TYPES = [
  { value: IDV_TYPE, title: "job.questions.types.idv" },
  { value: RTW_TYPE, title: "job.questions.types.rtw" },
  { value: DBS_TYPE, title: "job.questions.types.dbs" },
  { value: DBS_RTW_TYPE, title: "job.questions.types.rtwdbs" }
];

export const ALL_IDENTITY_VERIFICATION_TYPES = IDV_TYPES.map(
  item => item.value
);

export const COUNTRIES = [
  { iso: "AF", country: "Afghanistan" },
  { iso: "AX", country: "Åland Islands" },
  { iso: "AL", country: "Albania" },
  { iso: "DZ", country: "Algeria" },
  { iso: "AS", country: "American Samoa" },
  { iso: "AD", country: "Andorra" },
  { iso: "AO", country: "Angola" },
  { iso: "AI", country: "Anguilla" },
  { iso: "AQ", country: "Antarctica" },
  { iso: "AG", country: "Antigua & Barbuda" },
  { iso: "AR", country: "Argentina" },
  { iso: "AM", country: "Armenia" },
  { iso: "AW", country: "Aruba" },
  { iso: "AC", country: "Ascension Island" },
  { iso: "AU", country: "Australia" },
  { iso: "AT", country: "Austria" },
  { iso: "AZ", country: "Azerbaijan" },
  { iso: "BS", country: "Bahamas" },
  { iso: "BH", country: "Bahrain" },
  { iso: "BD", country: "Bangladesh" },
  { iso: "BB", country: "Barbados" },
  { iso: "BY", country: "Belarus" },
  { iso: "BE", country: "Belgium" },
  { iso: "BZ", country: "Belize" },
  { iso: "BJ", country: "Benin" },
  { iso: "BM", country: "Bermuda" },
  { iso: "BT", country: "Bhutan" },
  { iso: "BO", country: "Bolivia" },
  { iso: "BA", country: "Bosnia & Herzegovina" },
  { iso: "BW", country: "Botswana" },
  { iso: "BR", country: "Brazil" },
  { iso: "IO", country: "British Indian Ocean Territory" },
  { iso: "VG", country: "British Virgin Islands" },
  { iso: "BN", country: "Brunei" },
  { iso: "BG", country: "Bulgaria" },
  { iso: "BF", country: "Burkina Faso" },
  { iso: "BI", country: "Burundi" },
  { iso: "KH", country: "Cambodia" },
  { iso: "CM", country: "Cameroon" },
  { iso: "CA", country: "Canada" },
  { iso: "IC", country: "Canary Islands" },
  { iso: "CV", country: "Cape Verde" },
  { iso: "BQ", country: "Caribbean Netherlands" },
  { iso: "KY", country: "Cayman Islands" },
  { iso: "CF", country: "Central African Republic" },
  { iso: "EA", country: "Ceuta & Melilla" },
  { iso: "TD", country: "Chad" },
  { iso: "CL", country: "Chile" },
  { iso: "CN", country: "China" },
  { iso: "CX", country: "Christmas Island" },
  { iso: "CC", country: "Cocos (Keeling) Islands" },
  { iso: "CO", country: "Colombia" },
  { iso: "KM", country: "Comoros" },
  { iso: "CG", country: "Congo - Brazzaville" },
  { iso: "CD", country: "Congo - Kinshasa" },
  { iso: "CK", country: "Cook Islands" },
  { iso: "CR", country: "Costa Rica" },
  { iso: "CI", country: "Côte d’Ivoire" },
  { iso: "HR", country: "Croatia" },
  { iso: "CU", country: "Cuba" },
  { iso: "CW", country: "Curaçao" },
  { iso: "CY", country: "Cyprus" },
  { iso: "CZ", country: "Czechia" },
  { iso: "DK", country: "Denmark" },
  { iso: "DG", country: "Diego Garcia" },
  { iso: "DJ", country: "Djibouti" },
  { iso: "DM", country: "Dominica" },
  { iso: "DO", country: "Dominican Republic" },
  { iso: "EC", country: "Ecuador" },
  { iso: "EG", country: "Egypt" },
  { iso: "SV", country: "El Salvador" },
  { iso: "GQ", country: "Equatorial Guinea" },
  { iso: "ER", country: "Eritrea" },
  { iso: "EE", country: "Estonia" },
  { iso: "ET", country: "Ethiopia" },
  { iso: "EZ", country: "Eurozone" },
  { iso: "FK", country: "Falkland Islands" },
  { iso: "FO", country: "Faroe Islands" },
  { iso: "FJ", country: "Fiji" },
  { iso: "FI", country: "Finland" },
  { iso: "FR", country: "France" },
  { iso: "GF", country: "French Guiana" },
  { iso: "PF", country: "French Polynesia" },
  { iso: "TF", country: "French Southern Territories" },
  { iso: "GA", country: "Gabon" },
  { iso: "GM", country: "Gambia" },
  { iso: "GE", country: "Georgia" },
  { iso: "DE", country: "Germany" },
  { iso: "GH", country: "Ghana" },
  { iso: "GI", country: "Gibraltar" },
  { iso: "GR", country: "Greece" },
  { iso: "GL", country: "Greenland" },
  { iso: "GD", country: "Grenada" },
  { iso: "GP", country: "Guadeloupe" },
  { iso: "GU", country: "Guam" },
  { iso: "GT", country: "Guatemala" },
  { iso: "GG", country: "Guernsey" },
  { iso: "GN", country: "Guinea" },
  { iso: "GW", country: "Guinea-Bissau" },
  { iso: "GY", country: "Guyana" },
  { iso: "HT", country: "Haiti" },
  { iso: "HN", country: "Honduras" },
  { iso: "HK", country: "Hong Kong SAR China" },
  { iso: "HU", country: "Hungary" },
  { iso: "IS", country: "Iceland" },
  { iso: "IN", country: "India" },
  { iso: "ID", country: "Indonesia" },
  { iso: "IR", country: "Iran" },
  { iso: "IQ", country: "Iraq" },
  { iso: "IE", country: "Ireland" },
  { iso: "IM", country: "Isle of Man" },
  { iso: "IL", country: "Israel" },
  { iso: "IT", country: "Italy" },
  { iso: "JM", country: "Jamaica" },
  { iso: "JP", country: "Japan" },
  { iso: "JE", country: "Jersey" },
  { iso: "JO", country: "Jordan" },
  { iso: "KZ", country: "Kazakhstan" },
  { iso: "KE", country: "Kenya" },
  { iso: "KI", country: "Kiribati" },
  { iso: "XK", country: "Kosovo" },
  { iso: "KW", country: "Kuwait" },
  { iso: "KG", country: "Kyrgyzstan" },
  { iso: "LA", country: "Laos" },
  { iso: "LV", country: "Latvia" },
  { iso: "LB", country: "Lebanon" },
  { iso: "LS", country: "Lesotho" },
  { iso: "LR", country: "Liberia" },
  { iso: "LY", country: "Libya" },
  { iso: "LI", country: "Liechtenstein" },
  { iso: "LT", country: "Lithuania" },
  { iso: "LU", country: "Luxembourg" },
  { iso: "MO", country: "Macau SAR China" },
  { iso: "MK", country: "Macedonia" },
  { iso: "MG", country: "Madagascar" },
  { iso: "MW", country: "Malawi" },
  { iso: "MY", country: "Malaysia" },
  { iso: "MV", country: "Maldives" },
  { iso: "ML", country: "Mali" },
  { iso: "MT", country: "Malta" },
  { iso: "MH", country: "Marshall Islands" },
  { iso: "MQ", country: "Martinique" },
  { iso: "MR", country: "Mauritania" },
  { iso: "MU", country: "Mauritius" },
  { iso: "YT", country: "Mayotte" },
  { iso: "MX", country: "Mexico" },
  { iso: "FM", country: "Micronesia" },
  { iso: "MD", country: "Moldova" },
  { iso: "MC", country: "Monaco" },
  { iso: "MN", country: "Mongolia" },
  { iso: "ME", country: "Montenegro" },
  { iso: "MS", country: "Montserrat" },
  { iso: "MA", country: "Morocco" },
  { iso: "MZ", country: "Mozambique" },
  { iso: "MM", country: "Myanmar (Burma)" },
  { iso: "NA", country: "Namibia" },
  { iso: "NR", country: "Nauru" },
  { iso: "NP", country: "Nepal" },
  { iso: "NL", country: "Netherlands" },
  { iso: "NC", country: "New Caledonia" },
  { iso: "NZ", country: "New Zealand" },
  { iso: "NI", country: "Nicaragua" },
  { iso: "NE", country: "Niger" },
  { iso: "NG", country: "Nigeria" },
  { iso: "NU", country: "Niue" },
  { iso: "NF", country: "Norfolk Island" },
  { iso: "KP", country: "North Korea" },
  { iso: "MP", country: "Northern Mariana Islands" },
  { iso: "NO", country: "Norway" },
  { iso: "OM", country: "Oman" },
  { iso: "PK", country: "Pakistan" },
  { iso: "PW", country: "Palau" },
  { iso: "PS", country: "Palestinian Territories" },
  { iso: "PA", country: "Panama" },
  { iso: "PG", country: "Papua New Guinea" },
  { iso: "PY", country: "Paraguay" },
  { iso: "PE", country: "Peru" },
  { iso: "PH", country: "Philippines" },
  { iso: "PN", country: "Pitcairn Islands" },
  { iso: "PL", country: "Poland" },
  { iso: "PT", country: "Portugal" },
  { iso: "PR", country: "Puerto Rico" },
  { iso: "QA", country: "Qatar" },
  { iso: "RE", country: "Réunion" },
  { iso: "RO", country: "Romania" },
  { iso: "RU", country: "Russia" },
  { iso: "RW", country: "Rwanda" },
  { iso: "WS", country: "Samoa" },
  { iso: "SM", country: "San Marino" },
  { iso: "ST", country: "São Tomé & Príncipe" },
  { iso: "SA", country: "Saudi Arabia" },
  { iso: "SN", country: "Senegal" },
  { iso: "RS", country: "Serbia" },
  { iso: "SC", country: "Seychelles" },
  { iso: "SL", country: "Sierra Leone" },
  { iso: "SG", country: "Singapore" },
  { iso: "SX", country: "Sint Maarten" },
  { iso: "SK", country: "Slovakia" },
  { iso: "SI", country: "Slovenia" },
  { iso: "SB", country: "Solomon Islands" },
  { iso: "SO", country: "Somalia" },
  { iso: "ZA", country: "South Africa" },
  { iso: "GS", country: "South Georgia & South Sandwich Islands" },
  { iso: "KR", country: "South Korea" },
  { iso: "SS", country: "South Sudan" },
  { iso: "ES", country: "Spain" },
  { iso: "LK", country: "Sri Lanka" },
  { iso: "BL", country: "St. Barthélemy" },
  { iso: "SH", country: "St. Helena" },
  { iso: "KN", country: "St. Kitts & Nevis" },
  { iso: "LC", country: "St. Lucia" },
  { iso: "MF", country: "St. Martin" },
  { iso: "PM", country: "St. Pierre & Miquelon" },
  { iso: "VC", country: "St. Vincent & Grenadines" },
  { iso: "SD", country: "Sudan" },
  { iso: "SR", country: "Suriname" },
  { iso: "SJ", country: "Svalbard & Jan Mayen" },
  { iso: "SZ", country: "Swaziland" },
  { iso: "SE", country: "Sweden" },
  { iso: "CH", country: "Switzerland" },
  { iso: "SY", country: "Syria" },
  { iso: "TW", country: "Taiwan" },
  { iso: "TJ", country: "Tajikistan" },
  { iso: "TZ", country: "Tanzania" },
  { iso: "TH", country: "Thailand" },
  { iso: "TL", country: "Timor-Leste" },
  { iso: "TG", country: "Togo" },
  { iso: "TK", country: "Tokelau" },
  { iso: "TO", country: "Tonga" },
  { iso: "TT", country: "Trinidad & Tobago" },
  { iso: "TA", country: "Tristan da Cunha" },
  { iso: "TN", country: "Tunisia" },
  { iso: "TR", country: "Turkey" },
  { iso: "TM", country: "Turkmenistan" },
  { iso: "TC", country: "Turks & Caicos Islands" },
  { iso: "TV", country: "Tuvalu" },
  { iso: "UM", country: "U.S. Outlying Islands" },
  { iso: "VI", country: "U.S. Virgin Islands" },
  { iso: "UG", country: "Uganda" },
  { iso: "UA", country: "Ukraine" },
  { iso: "AE", country: "United Arab Emirates" },
  { iso: "GB", country: "United Kingdom" },
  { iso: "UN", country: "United Nations" },
  { iso: "US", country: "United States" },
  { iso: "UY", country: "Uruguay" },
  { iso: "UZ", country: "Uzbekistan" },
  { iso: "VU", country: "Vanuatu" },
  { iso: "VA", country: "Vatican City" },
  { iso: "VE", country: "Venezuela" },
  { iso: "VN", country: "Vietnam" },
  { iso: "WF", country: "Wallis & Futuna" },
  { iso: "EH", country: "Western Sahara" },
  { iso: "YE", country: "Yemen" },
  { iso: "ZM", country: "Zambia" },
  { iso: "ZW", country: "Zimbabwe" }
];
export const RTW_COUNTRY_OPTIONS = [
  { iso: "IE", country: "Ireland" },
  { iso: "GB", country: "United Kingdom" },
  { iso: "IM", country: "Isle of Man" },
  { iso: "GG", country: "Guernsey" },
  { iso: "JE", country: "Jersey" }
];

export const CANDIDATE_INTERVIEW_SUBMITTED = "interview_submitted";
export const CANDIDATE_INTERVIEW_CLOSED = "interview_closed";

export const ANSWER_STATUS = {
  processing: "processing",
  processed: "processed",
  received: "received",
  failed: "failed",
  unsafe: "unsafe"
};

export const AVAILABLE_PLANS = "Growth, Scale, API";

export const AVAILABLE_PLANS_FOR_IDV = "Starter, Growth, Scale, API";

export const ALL_IDV_REJECTED_REASON = [
  "COUNTERFEIT",
  "DIFFERENT_PERSON",
  "DOC_NUMBER_INVALID",
  "DOCUMENT_COPY",
  "FACE_MATCH_VERIFICATION_FAILED",
  "FRAUD_DETECTED",
  "FRAUD_LIST_MATCH",
  "LARGE_AGE_GAP",
  "MANDATORY_DOCUMENT_NOT_PROVIDED",
  "MISSING_LIVENESS",
  "NO_DOCUMENT",
  "PAN_DATABASE_NAME_MISMATCH",
  "PHOTO_OF_PHOTO",
  "TAMPERED",
  "UNABLE_TO_VALIDATE_DOCUMENT",
  "FACE_MATCH_HIGHER_THRESHOLD_VERIFICATION_FAILED",
  "PHOTO_OVEREXPOSED",
  "PHOTO_TOO_DARK",
  "PHOTO_TOO_BLURRY",
  "DOCUMENT_TOO_DAMAGED",
  "GLARE_OBSTRUCTION",
  "OBJECT_OBSTRUCTION",
  "PARTIAL_PHOTO",
  "IMAGE_RESOLUTION_TOO_LOW",
  "COUNTRY_NOT_SUPPORTED",
  "DOCUMENT_NOT_SUPPORTED",
  "INCORRECT_DOCUMENT_TYPE",
  "INCORRECT_MRZ",
  "DOCUMENT_VERSION_NOT_SUPPORTED",
  "MISSING_DOCUMENT_SIDE",
  "BLACK_AND_WHITE_IMAGE",
  "MISUSE",
  "INVALID",
  "CHIP_PARSE",
  "CHIP_SOD_PARSE",
  "EXPIRED_DOCUMENT",
  "ISSUING_AUTHORITY_INVALID",
  "MISSING_HOLOGRAM",
  "NO_HOLOGRAM_MOVEMENT",
  "DATA_MISMATCH",
  "CHIP_DATA_INTEGRITY_FAILED",
  "CHIP_SIGNATURE_VERIFICATION_FAILED",
  "CHIP_CSCA_VERIFICATION_FAILED",
  "MISMATCH",
  "NON_LATIN_CHARACTER_IN_NAME",
  "NO_VALID_SUBCHECKS",
  "UNABLE_TO_COMPLETE_CHECKS"
];

export const IDVStatusIcon = {
  accepted: <Check height={15} width={15} />,
  inconclusive: <Questionmark />,
  Omitted: <Questionmark />,
  skipped: <Questionmark />,
  rejected: <Close fill="#E83B3B" height="15" width="15" />
};

export const IDVStatusChip = {
  accepted: (
    <div
      className="idv-filechip"
      style={{
        background: "#0FAC00"
      }}
    >
      Accepted
    </div>
  ),
  inconclusive: (
    <div
      className="idv-filechip"
      style={{
        background: "#FFC107"
      }}
    >
      Inconclusive
    </div>
  ),
  Omitted: (
    <div
      className="idv-filechip"
      style={{
        background: "#FFC107"
      }}
    >
      Omitted
    </div>
  ),
  skipped: (
    <div
      className="idv-filechip"
      style={{
        background: "#FFC107"
      }}
    >
      Skipped
    </div>
  ),
  rejected: (
    <div
      className="idv-filechip"
      style={{
        background: "#E83B3B"
      }}
    >
      Rejected
    </div>
  ),
  aborted: (
    <div
      className="idv-filechip"
      style={{
        background: "#FFC107"
      }}
    >
      Aborted
    </div>
  )
};


export const NOT_AVAILABLE = "not_available";
export const EMPTY = "empty";
export const FAILED = "failed";
