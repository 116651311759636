import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { ALL_IDENTITY_VERIFICATION_TYPES } from "configs/jobs/constants";
import useWindowResize from "hooks/useWindowResize.ts";

const CandidateAnswerTitle = ({
  t,
  question,
  isJobCandidateLoading,
  answerKey
}) => {
  const { isMobileAndTabletScreen } = useWindowResize();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);
  const divRef = useRef(null);
  const resizeObserver = useRef(null);

  const questionKey = useMemo(() => question?.key, [question]);

  // Check if the text is truncated
  const checkIsEllipsisActive = useCallback(() => {
    if (!textRef.current) return false; // Check if the reference is valid

    const originalElement = textRef.current;
    const cloneElement = originalElement.cloneNode(true);

    // Temporarily style the clone to measure its height correctly
    cloneElement.style.webkitLineClamp = "unset";
    cloneElement.style.maxHeight = "none";
    cloneElement.style.visibility = "hidden";
    cloneElement.style.position = "absolute";
    cloneElement.style.width = `${originalElement.offsetWidth }px`; // Match the width of the original
    divRef.current.appendChild(cloneElement);

    const isTruncated = cloneElement.offsetHeight > originalElement.offsetHeight;

    divRef.current.removeChild(cloneElement);
    return isTruncated;
  }, []);

  // Update the visibility of the button based on the content
  const updateButtonVisibility = useCallback(() => {
    if (textRef.current && !isJobCandidateLoading) {
      setShowButton(checkIsEllipsisActive());
    }
  }, [checkIsEllipsisActive, isJobCandidateLoading]);

  // Effect to handle changes and updates
  useEffect(() => {
    setIsExpanded(false);
    updateButtonVisibility();

    // Resize observer to detect changes in size
    if (resizeObserver.current) resizeObserver.current.disconnect();
    resizeObserver.current = new ResizeObserver(() => {
      updateButtonVisibility();
    });

    if (textRef.current) {
      resizeObserver.current.observe(textRef.current);
    }

    return () => {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    };
  }, [questionKey, answerKey, isJobCandidateLoading, updateButtonVisibility]);

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div
      className="candidateanswer__section__header-title__text"
      key={`title-${questionKey}`}
      ref={divRef}
    >
      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          height: 20,
          width: isMobileAndTabletScreen ? "100%" : "30rem",
          enableAnimation: true
        }}
        isLoading={isJobCandidateLoading}
      >
        <div
          className={`expandable-text__content ${isExpanded ? "expanded" : "truncated"}`}
          ref={textRef}
          key={question?.key}
        >
          {ALL_IDENTITY_VERIFICATION_TYPES.includes(question?.answer_type)
            ? t(`job.candidate.overview.idv.answer.thumbnail.${question?.answer_type}`)
            : ReactHtmlParser(question?.html_text) || question?.text}
        </div>
      </SkeletonWrapper>

      {showButton && !isJobCandidateLoading && (
        <button
          className="expandable-text__toggle button__without-styles w-full flex justify-end"
          onClick={toggleExpand}
          type="button"
          aria-label={isExpanded ? "See less" : "See more"}
        >
          {isExpanded ? "See less" : "See more"}
        </button>
      )}
    </div>
  );
};

export default withTranslation()(CandidateAnswerTitle);
