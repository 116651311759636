/* eslint-disable camelcase */

import { SUMMARY_STATUS } from "./summaries.ts";

export enum REGISTRATION_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected"
}

export type CurrentUserType = {
  answers_summaries_status: SUMMARY_STATUS;
  key: string;
  registration_status?: REGISTRATION_STATUS;
}
