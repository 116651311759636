/* eslint-disable no-nested-ternary */
import React from "react";
import { Check, Close } from "mixins/svgIcons";
import { getStepState, isQuestionCurrentActive } from "utils/stepper.ts";
import { CandidateAnswersType, Question } from "types/store/candidateAnswer.ts";

type BottomSheetProps = {
  isOpen: boolean;
  selectedStep: string | null;
  onClose: () => void;
  questions: Question[];
  savedAnswers: CandidateAnswersType;
  currentStepActive: string;
  handleStepClick: (questionId: string, index: number) => void;
};

const BottomSheet = ({
  isOpen,
  selectedStep,
  onClose,
  questions,
  savedAnswers,
  currentStepActive,
  handleStepClick
}: BottomSheetProps) => {
  if (!isOpen || !selectedStep) return null;

  const selectedQuestion = questions.find(q => q.key === selectedStep);
  if (!selectedQuestion) return null;

  const state = getStepState(selectedStep, currentStepActive, savedAnswers);
  const isCurrentQuestionSaved = savedAnswers.some(
    item => item.question.key === selectedStep
  );

  return (
    <div
      className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
      onClick={onClose}
      onKeyDown={e => e.key === "Escape" && onClose()}
      role="button"
      tabIndex={0}
    >
      <div
        className="absolute bottom-0 left-0 right-0 bg-white rounded-t-xl p-4 transform transition-transform duration-300"
        onClick={e => e.stopPropagation()}
        role="presentation"
      >
        {/* Pull Indicator */}
        <div className="absolute -top-2 left-1/2 transform -translate-x-1/2">
          <div className="w-12 h-1 bg-gray-300 rounded-full" />
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center
                ${state === "completed" ? "bg-green-100 text-green-700" :
                state === "current" ? "bg-indigo-600 text-white" :
                  "bg-gray-100 text-gray-600"}`}
              >
                {state === "completed" ? <Check svgIconClass="w-4 h-4" /> : selectedQuestion.order}
              </div>
              <h3 className="text-lg font-semibold">
                {`Question ${selectedQuestion.order}`}
              </h3>
            </div>
            <button
              type="button"
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Close svgIconClass="w-5 h-5" />
            </button>
          </div>

          <p className="text-lg max-h-20 truncated-text">{selectedQuestion.text}</p>

          <div className="flex items-center space-x-2">
            <Check svgIconClass="w-4 h-4 text-green-600" />
            <span className="text-green-600">Completed</span>
          </div>

          {isQuestionCurrentActive(selectedStep, currentStepActive) && isCurrentQuestionSaved && (
            <button
              type="button"
              onClick={() => {
                onClose();
                const questionIndex = questions.findIndex(q => q.key === selectedStep);
                handleStepClick(selectedStep, questionIndex);
              }}
              className="w-full bg-gray-100 hover:bg-gray-200 text-gray-800 py-3 px-4 rounded-lg flex items-center justify-center space-x-2 transition-colors"
            >
              <span>Go to this question</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomSheet;
