import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import Notification from "components/Notifications/Notification";
import NoConnection from "assets/images/candidate/no-connection.svg";

import "./styles.scss";
import { store } from "store";
import { saveRefreshRoute, setHealthCheckError } from "store/modules/widgets/actions";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "assets/images/components/onboarding/right-image.json";
import { isCurrentUserCandidate } from "mixins/helperCandidate";
import { getRefreshRoute } from "store/modules/widgets/selectors";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Maintenance = ({
  t,
  refreshStatus,
  isOnline,
  healthcheckError,
  history
}) => {
  const [refreshSeconds, setRefreshSeconds] = useState(30);
  const timer = useRef();

  const isForcedMaintenance = process.env.REACT_APP_REDIRECT_ALL_TO_MAINTENANCE === "true";
  const isConnectionLostPageEnabled = process.env.REACT_APP_CONNECTION_LOST_ENABLED === "true";

  const shouldAutoRefresh = useMemo(() => isOnline && isConnectionLostPageEnabled
    && healthcheckError?.includes("Network Error") && !isCurrentUserCandidate,
  [healthcheckError, isConnectionLostPageEnabled, isOnline]);

  useEffect(() => {
    if (shouldAutoRefresh && !isCurrentUserCandidate) {
      const route = getRefreshRoute(store.getState());
      store.dispatch(saveRefreshRoute(null));
      history.push(route || "/dashboard");
      store.dispatch(setHealthCheckError(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoRefresh]);

  useEffect(() => {
    if (refreshSeconds > 0 && !isForcedMaintenance) {
      timer.current = setTimeout(() => setRefreshSeconds(refreshSeconds - 1), 1000);
    } else {
      refreshStatus();
      setRefreshSeconds(30);
    }

    return () => clearInterval(timer.current);
  }, [isForcedMaintenance, refreshSeconds, refreshStatus]);

  const handleClick = () => {
    refreshStatus();
    setRefreshSeconds(30);
  };

  if (shouldAutoRefresh && !isCurrentUserCandidate) {
    return (
      <PageWrapper>
        <Container className="idv-checkout-session__container">
          <Row>
            <Col>
              <Lottie options={defaultOptions} height={200} width={250} />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {
        !isForcedMaintenance ? (
          <Notification
            type="trial"
            message={t("maintenance.refreshing", { time: `${refreshSeconds}s` })}
            withCloseIcon={false}
            style={{ justifyContent: "center" }}
            buttonOnClick={handleClick}
            buttonText={t("button.tryNow")}
          />
        ) : null
      }
      <div className="maintenance__wrapper">
        <div className="maintenance">
          <div className="w-full flex items-center justify-center mx-auto">
            <img src={NoConnection} alt="willo" />
          </div>

          <div>
            <h3 className="maintenance__title">
              {t("maintenance.header")}
            </h3>

            <p className="maintenance__text">
              {t("maintenance.sorry")}
            </p>
            <a
              href="https://willovideo.freshstatus.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="button button__submit button__purple button__purple_shadow n-button__full-width n-button__medium maintenance__button"
            >
              {t("button.viewStatus")}
            </a>
            <p className="maintenance__text">
              {t("maintenance.thankYou")}
            </p>
            <p className="maintenance__text">
              {t("maintenance.willoTeam")}
            </p>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

Maintenance.defaultProps = {
  healthcheckError: undefined
};

Maintenance.propTypes = {
  t: PropTypes.func.isRequired,
  refreshStatus: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
  healthcheckError: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withLoaderScreen(withTranslation()(Maintenance));
