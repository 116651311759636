import React, { ReactNode, useState, useRef, useEffect } from "react";

type CustomTooltipProps = {
  content: ReactNode;
  children: ReactNode;
  isEnabledTooltip?: boolean;
  containerClassName?: string;
}


const CustomTooltip = ({ content, children, isEnabledTooltip = true, containerClassName = "" }: CustomTooltipProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [childHeight, setChildHeight] = useState<number>(0);
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childRef.current) {
      setChildHeight(childRef.current.offsetHeight);
    }
  }, [children]);

  if (!isEnabledTooltip) return children;

  return (
    <div className={`relative inline-block ${containerClassName}`}>
      <div
        ref={childRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          className="absolute z-10 w-56 lg:w-64 p-2 lg:p-4 left-0 bg-white rounded-lg shadow-lg border border-gray-200"
          style={{ top: `${childHeight}px` }}
        >
          <div className="text-sm text-gray-700">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
