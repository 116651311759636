/* eslint-disable react/button-has-type */
import CommentsIcon from "assets/images/candidate/CommentsIcon";
import TextIcon from "assets/images/candidate/TextIcon";
import TranscriptionIcon from "assets/images/candidate/TranscriptionIcon";
import React from "react";
import PropTypes from "prop-types";
import TooltipInfo from "components/Common/Tooltip/Info";
import { useTranslation } from "react-i18next";
import { size } from "lodash";
import { getComments } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import useWindowResize from "hooks/useWindowResize.ts";

const CandidateWidget = ({
  setOpenSidebar,
  activeSidebar,
  comments
}) => {
  const { t } = useTranslation();
  const { isMobileScreen } = useWindowResize();

  return (
    <div className="candidatewidget">
      <TooltipInfo message={t("sidebarWidget.transcription")}>
        <button
          className="flex items-center justify-center mb-2 w-10 candidate_button"
          onClick={() => setOpenSidebar(activeSidebar === "transcription" ? null : "transcription")}
          aria-label="Transcription"
        >
          <TranscriptionIcon
            fill={activeSidebar === "transcription" ? "#5A2AF1" : "#59595980"}
          />
        </button>
      </TooltipInfo>

      <TooltipInfo message={t("sidebarWidget.summary")}>
        <button
          className="flex items-center justify-center mb-2 w-10 candidate_button"
          onClick={() => setOpenSidebar(activeSidebar === "summary" ? null : "summary")}
          aria-label="Summary"
        >
          <TextIcon
            fill={activeSidebar === "summary" ? "#5A2AF1" : "#59595980"}
          />
        </button>
      </TooltipInfo>

      <TooltipInfo message={t("sidebarWidget.comment")}>
        <button
          className="flex flex-col items-center justify-center hover:text-main-purple py-2 w-10 candidate_button-comment"
          onClick={() => setOpenSidebar((activeSidebar === "comment" ? null : "comment"))}
          style={{
            color: activeSidebar === "comment" ? "#5A2AF1" : "#59595980"
          }}
          aria-label="Comment"
        >
          <CommentsIcon
            fill={activeSidebar === "comment" ? "#5A2AF1" : "#59595980"}
          />
          {size(comments) !== 0 && !isMobileScreen ? size(comments) : ""}
        </button>
      </TooltipInfo>
    </div>
  );
};

CandidateWidget.defaultProps = {
  setOpenSidebar: () => {},
  activeSidebar: ""
};

CandidateWidget.propTypes = {
  setOpenSidebar: PropTypes.func,
  activeSidebar: PropTypes.string
};

const mapStateToProps = state => ({
  comments: getComments(state)
});

export default connect(mapStateToProps)(CandidateWidget);
