/* eslint-disable react/prop-types */
import React from "react";

import StatusJob from "components/Status/Job";
import TooltipInfo from "components/Common/Tooltip/Info";
import { JOB_STATUSES } from "configs/jobs/constants";
import { Location } from "mixins/svgIcons";
import { FaUserFriends } from "react-icons/fa";

const Job = ({ isShowCreatedBy = false, job, createdBy, company }) => (
  <header className="header-job__header">
    <h1 className="header-job__header-title n-font-giant">
      <span className="header-job__header-title-text">{job.title}</span>
      <StatusJob
        isLive={job.status === JOB_STATUSES.LIVE}
        prefix={job.status === JOB_STATUSES.EXPIRED_CLOSED ? JOB_STATUSES.EXPIRED_CLOSED : ""}
      />
      {isShowCreatedBy && (
        <TooltipInfo
          prefix="md"
          tooltipPrefix="with-white-bg"
          message={`Shared with you by ${createdBy}`}
          element={<i className="fas fa-user-friends md grey"><FaUserFriends style={{ marginTop: -8 }} /></i>}
        />
      )}
    </h1>

    <p className="header-job__wrapper n-font-medium">
      <span className="header-job__additional-info-company">
        {company.name || ""}
      </span>
      {company.location ? (
        <span className="flex flex-row items-center">
          <Location svgIconClass="header-job__additional-info-icon" />
          {company.location}
        </span>
      ) : null}
    </p>
  </header>
);

export default Job;
