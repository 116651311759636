import { connect } from "react-redux";
import router from "router";
import { getToken } from "store/modules/auth/selectors";

import { getIsTrialCompleted, getIsSubscriptionFailed, getNotifications, getIsTrialOrFree, getIsTrialOrPaid, getIsNotificationsLoading } from "store/modules/notifications/selectors";
import { getCurrentUser, getCurrentUserId, getIsAccountLocked } from "store/modules/users/selectors";
import { refreshStatus } from "store/modules/widgets/actions";
import { getIsOnline } from "store/modules/widgets/selectors";

const mapStateToProps = state => {
  const {
    is_admin_user: hasAdminRight = false
  } = getNotifications(state);

  const user = getCurrentUser(state);
  const isSetupCompleted = user?.is_setup_completed ?? true;

  return {
    isTrialCompleted: getIsTrialCompleted(state),
    isSubscriptionFailed: getIsSubscriptionFailed(state),
    isTrialOrFree: getIsTrialOrFree(state),
    hasAdminRight,
    isSetupCompleted,
    isTrialOrPaid: getIsTrialOrPaid(state),
    userId: getCurrentUserId(state),
    isOnline: getIsOnline(state),
    notificationsLoading: getIsNotificationsLoading(state),
    isAccountLocked: getIsAccountLocked(state),
    isAuthenticated: Boolean(getToken(state)),
    agencyKey: getCurrentUser(state)?.key
  };
};

const mapDispatchToProps = {
  refreshStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(router);
