/* eslint-disable no-confusing-arrow */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import {
  delay,
  takeLatest,
  takeEvery,
  select,
  call,
  put,
  all
} from "redux-saga/effects";
// eslint-disable-next-line import/no-cycle
import { history } from "store";
import pick from "lodash/pick";
import uuidv4 from "uuid/v4";
import {
  setSidebarCommentsData
} from "store/modules/sidebar/actions";

import {
  isJobsLoading,
  isLatestJobsLoading,
  isSendingUploading,
  isCandidatesStatsLoading,
  isNextCandidatesLoading,
  isJobStatsLoading,
  saveJob,
  saveJobResponse,
  updateJobResponse,
  saveJobError,
  updateJobUsersList,
  updateJobUsersListResponse,
  duplicateJob,
  duplicateJobResponse,
  duplicateJobSuccess,
  duplicateJobError,
  clearJobs,
  fetchJobs,
  fetchLatestJobs,
  fetchJobsResponse,
  fetchLatestJobsResponse,
  restoreJob,
  restoreJobResponse,
  deleteJob,
  deleteJobResponse,
  sendSpreadsheet,
  sendSpreadsheetResponse,
  sendSpreadsheetError,
  sendInvites,
  sendInvitesResponse,
  sendInvitesError,
  clearJobInvites,
  fetchCompaniesForQuestion,
  fetchCompaniesForQuestionResponse,
  fetchCompaniesForQuestionError,
  fetchIndustries,
  fetchCandidatesList,
  fetchNextCandidatesList,
  fetchNextCandidatesListResponse,
  fetchNextCandidatesListError,
  fetchCandidatesByStatusList,
  fetchCandidatesByStatusListResponse,
  fetchCandidatesByStatusListError,
  updateCandidatesList,
  downloadCandidatesList,
  fetchCandidatesListResponse,
  fetchCandidatesListError,
  fetchCandidateHistory,
  fetchCandidateHistoryResponse,
  fetchCandidateHistoryError,
  fetchIndustriesResponse,
  fetchCurrentJob,
  fetchCurrentJobResponse,
  fetchCurrentJobError,
  fetchUserCurrentJob,
  fetchUserCurrentJobResponse,
  fetchUserCurrentJobError,
  fetchCandidateAnswers,
  fetchCandidateAnswersResponse,
  fetchCandidateAnswersError,
  fetchJobsStats,
  fetchJobsStatsResponse,
  fetchJobsStatsError,
  fetchJobCandidatesStats,
  fetchJobCandidatesStatsResponse,
  fetchJobCandidatesStatsError,
  fetchJobCandidatesStatsFilterResponse,
  updateCandidateStatus,
  updateCandidateStatusResponse,
  updateCandidateStatusError,
  deleteCandidate,
  deleteCandidateFromInterviewersList,
  deleteCandidateResponseError,
  clearSetupState,
  clearQuestionsState,
  clearPreviewState,
  clearSuccessMessage,
  clearErrors,
  saveSetupInfo,
  setVideoError,
  fetchSuggestions,
  setSuggestions,
  sendReminderCandidate,
  setSelectedCandidatesByStatus,
  setSelectedCandidatesByStatusResponse,
  setSelectedCandidatesLoading,
  fetchCandidateInformation,
  setCandidateInformationLoading,
  fetchJobUsersList,
  fetchJobUsersListResponse,
  fetchJobUsersListError,
  fetchNextJobUsersList,
  fetchNextJobUsersListResponse,
  fetchNextJobUsersListError,
  isNextJobUsersLoading,
  isJobUsersLoading,
  fetchJobsFilters,
  fetchJobsFiltersResponse,
  fetchJobsFiltersError,
  setJobsFiltersLoading,
  fetchLanguagesForQuestion,
  fetchLanguagesForQuestionResponse,
  fetchCandidateIdvLoading,
  fetchCandidateIdvResponse,
  fetchCandidateIdvError,
  fetchCandidateIdvDocumentImageLoading,
  fetchCandidateIdvDocumentImageResponse,
  fetchCandidateIdvDocumentImageError,
  fetchCandidateIdvIdentityImageLoading,
  fetchCandidateIdvIdentityImageResponse,
  fetchCandidateIdvIdentityImageError,
  fetchCandidateIdv,
  fetchCandidateIdvPdfLoading,
  fetchCandidateIdvPdfError,
  fetchCandidateIdvPdfResponse,
  fetchCandidateAnswer,
  updateJobsListAtsDetails,
  isJobAtsLoading,
  getJobAtsDetailsResponse,
  getJobAtsDetails,
  updateAvailableAtsJobs,
  getAvailableAtsJobStagesResponse,
  getAvailableAtsJobStages,
  getNextAtsJobs,
  getAvailableAtsJobStagesLoading,
  updateNextAvailableAtsJobs,
  subscribeToAtsJob,
  updateConnectedAtsJobsAndStages,
  subscribeToAtsJobLoading,
  fetchJobsCompaniesFilter,
  getNextAtsJobsLoading,
  fetchJobsOwnersFilter,
  fetchJobsStatusesFilter,
  fetchJobsStatusesFilterResponse,
  fetchJobsOwnersFilterResponse,
  fetchJobsCompanyFilterResponse,
  filterAtsJobs,
  filterAtsJobsResponse,
  filterAtsJobsLoading,
  subscribeToAtsJobResponse,
  setSelectedCandidates,
  saveCandidatesExportLink,
  isJobCandidateLoading,
  saveJobErrorWithFields,
  fetchCandidateHistoryLoading,
  isCommentsLoading
} from "store/modules/jobs/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getToken } from "store/modules/auth/selectors";
import {
  getJobInvitesData,
  getNextCandidates,
  getUserCurrentJob,
  getNextJobUsers,
  getCandidateAnswers,
  getJobAts,
  getSelectedCandidates,
  getHasSelectAllStatus,
  getExcludedCandidates,
  getUserCurrentInterviewersPagination,
  getUserCurrentInterviewersList
} from "store/modules/jobs/selectors";
import CANDIDATE_STATUSES from "configs/candidates";
import { checkPhoneValidity } from "mixins/helperJobInvites";
import isEmpty from "lodash/isEmpty";

// eslint-disable-next-line import/no-cycle
import { logErrors } from "mixins/helperLogging";
import { capitalize, isArray, uniqBy } from "lodash";
import { ALL_IDENTITY_VERIFICATION_TYPES, JOB_STATUSES } from "configs/jobs/constants";
import { getCurrentUser, getCurrentUserId, getJobsMaximum } from "store/modules/users/selectors";
import {
  getRequestWithToken,
  postRequestWithToken,
  patchRequestWithToken,
  putRequestWithToken,
  deleteRequestWithToken,
  uploadRequestWithToken,
  getFullUrlRequestWithToken
} from "./api";
import {
  fetchCandidateScoreByCardIdWorker,
  fetchCandidateScoresWorker
} from "./scorecards";
import { handleOnboardingWorker } from "./widgets";
import { updateIsOpenVideoArchives } from "../store/modules/сandidates/actions";

export function* fetchCompaniesForQuestionWorker({ payload = "" }) {
  try {
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/companies/agency/${payload}/options`,
      token
    });

    yield put(fetchCompaniesForQuestionResponse(response.data));
  } catch (error) {
    console.log("[requestError]", error);
    yield put(fetchCompaniesForQuestionError());
  }
}

export function* fetchLanguagesForQuestionWorker() {
  try {
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: "/api/common/languages/",
      token
    });

    yield put(fetchLanguagesForQuestionResponse(response.data));
  } catch (error) {
    console.log("[fetchLanguagesForQuestion]", error);
  }
}

export function* fetchIndustriesWorker() {
  try {
    yield put(isJobsLoading(true));

    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: "/api/common/industries/",
      token
    });

    yield put(fetchIndustriesResponse(response.data));
  } catch (error) {
    console.log("[requestError]", error);
  } finally {
    yield put(isJobsLoading(false));
  }
}

export function* restoreJobWorker({ payload = {} }) {
  try {
    const token = yield select(getToken);
    const response = yield postRequestWithToken({
      url: `/api/job/${payload}/restore/`,
      token
    });

    const agency = yield select(getCurrentUser);
    const agencyKey = agency?.key;

    if (response.status === 200) {
      yield put(restoreJobResponse(payload));
      yield call(handleOnboardingWorker, {
        payload: {
          key: agencyKey
        }
      });
    }
  } catch (error) {
    console.log("[restoreJobWorker]", error);
  }
}

export function* deleteJobWorker({
  payload: { jobId = "", queryParam = "hide" } = {}
}) {
  try {
    const token = yield select(getToken);
    const agency = yield select(getCurrentUser);
    const agencyKey = agency?.key;

    const response = yield deleteRequestWithToken({
      url: `/api/job/${jobId}/${queryParam}/`,
      token
    });

    if (response?.status === 204) {
      yield call(handleOnboardingWorker, {
        payload: {
          key: agencyKey
        }
      });

      if (queryParam === "hide") {
        yield put(fetchCurrentJob(jobId));
      } else {
        yield put(deleteJobResponse(jobId));
      }
    }
  } catch (error) {
    console.log("[deleteJobRequestError]", error);
  }
}

const getFilterQuery = ({ key, filter }) =>
  `search=${filter.title || ""}&agency=${key || ""}&${[
    { name: "companies", query: "company" },
    { name: "owners", query: "owner" },
    { name: "statuses", query: "status" }
  ]
    .map(({ name, query }) =>
      (filter[name] || []).map(({ value }) => `${query}=${value}`).join("&"))
    .join("&")}`;

export function* fetchJobsCompaniesFilterWorker({
  payload: { key, filter } = {}
}) {
  //
  try {
    yield put(setJobsFiltersLoading(true));
    const token = yield select(getToken);
    const res = yield getRequestWithToken({
      url: `/api/job/filter/companies/?${getFilterQuery({
        key,
        filter: {
          ...filter,
          title: ""
        },
        currFilter: "companies"
      })}`,
      token
    });

    yield put(fetchJobsCompanyFilterResponse(res.data));
  } catch (error) {
    console.error("[fetchJobsFiltersError]", error);
    yield put(fetchJobsFiltersError(error));
  } finally {
    yield put(setJobsFiltersLoading(false));
  }
}

export function* fetchJobsOwnersFilterWorker({
  payload: { key, filter } = {}
}) {
  try {
    yield put(setJobsFiltersLoading(true));
    const token = yield select(getToken);
    const res = yield getRequestWithToken({
      url: `/api/job/filter/owners/?${getFilterQuery({
        key,
        filter: {
          ...filter,
          title: ""
        },
        currFilter: "owners"
      })}`,
      token
    });

    yield put(fetchJobsOwnersFilterResponse(res.data));
  } catch (error) {
    console.error("[fetchJobsFiltersError]", error);
    yield put(fetchJobsFiltersError(error));
  } finally {
    yield put(setJobsFiltersLoading(false));
  }
}

export function* fetchJobsStatusesFilterWorker({
  payload: { key, filter } = {}
}) {
  try {
    yield put(setJobsFiltersLoading(true));
    const token = yield select(getToken);
    const res = yield getRequestWithToken({
      url: `/api/job/filter/statuses/?${getFilterQuery({
        key,
        filter: {
          ...filter,
          title: ""
        },
        currFilter: "statuses"
      })}`,
      token
    });

    yield put(fetchJobsStatusesFilterResponse(res.data));
  } catch (error) {
    console.error("[fetchJobsFiltersError]", error);
    yield put(fetchJobsFiltersError(error));
  } finally {
    yield put(setJobsFiltersLoading(false));
  }
}

export function* fetchJobsFiltersWorker({ payload: { key, filter } = {} }) {
  try {
    yield put(setJobsFiltersLoading(true));

    const token = yield select(getToken);

    const [responseCompanies, responseOwners, responseStatuses] = yield all(
      ["companies", "owners", "statuses"].map(currFilter =>
        getRequestWithToken({
          url: `/api/job/filter/${currFilter}/?${getFilterQuery({
            key,
            filter: {
              ...filter,
              title: ""
            },
            currFilter
          })}`,
          token
        }))
    );

    yield put(
      fetchJobsFiltersResponse({
        companies: responseCompanies.data,
        owners: responseOwners.data,
        statuses: responseStatuses.data
      })
    );
  } catch (error) {
    console.error("[fetchJobsFiltersError]", error);
    yield put(fetchJobsFiltersError(error));
  } finally {
    yield put(setJobsFiltersLoading(false));
  }
}

export function* fetchJobsListAtsDetailsWorker({ job }) {
  try {
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      token,
      url: `/api/marketplace/integration/interview/${job.key}/callbacks/ats/`
    });

    yield put(
      updateJobsListAtsDetails({
        response: response.data,
        interviewKey: job.key
      })
    );
  } catch (error) {
    const agencyKey = yield select(getCurrentUserId);
    logErrors({ error, context: { userId: agencyKey, jobId: job?.key, type: "recruiter" } });
  }
}

// eslint-disable-next-line consistent-return
export function* fetchJobsWorker({ payload = {} }) {
  try {
    const {
      key = "",
      withFilterUpdate = false,
      filter = { title: "", company: null, owners: [] }
    } = payload;
    yield put(clearJobs());
    yield put(isJobsLoading(true));
    if (withFilterUpdate) {
      yield put(fetchJobsFilters(payload));
    }

    const token = yield select(getToken);

    if (key && token) {
      const response = yield getRequestWithToken({
        url: `/api/job/?page_size=20&${getFilterQuery({ key, filter })}`,
        token
      });

      yield put(fetchJobsResponse(response.data));

      const atsJobsList = response?.data?.results?.filter(
        job => job?.has_ats_connection
      );

      if (atsJobsList) {
        yield all(
          atsJobsList.map(job =>
            fetchJobsListAtsDetailsWorker({
              job
            }))
        );
      }

      return response.data;
    }
  } catch (error) {
    console.log("[fetchJobsRequestError]", error);
  } finally {
    yield put(isJobsLoading(false));
  }
}

// eslint-disable-next-line consistent-return
export function* fetchLatestJobsWorker({ payload: { next } = {} }) {
  try {
    yield put(isLatestJobsLoading(true));
    const token = yield select(getToken);

    if (next && token) {
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-useless-escape
      const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestJobsResponse(response.data));

      const atsJobsList = response?.data?.results?.filter(
        job => job?.has_ats_connection
      );

      if (atsJobsList) {
        yield all(
          atsJobsList.map(job =>
            fetchJobsListAtsDetailsWorker({
              job
            }))
        );
      }

      return response.data;
    }
  } catch (error) {
    console.log("[fetchLatestJobsRequestError]", error);
  } finally {
    yield put(isLatestJobsLoading(false));
  }
}

export function* fetchJobsStatsWorker({ payload: { id = "" } } = {}) {
  try {
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      token,
      url: `/api/job/stats/?company=${id}`
    });

    yield put(fetchJobsStatsResponse(response.data));
  } catch (error) {
    console.error("Could not get company jobs", error);

    yield put(fetchJobsStatsError("Could not get data for company jobs"));
  }
}

export function* fetchJobCandidatesStatsWorker({
  payload: { jobId = "", dateRange: { value = "" } = {} } = {}
} = {}) {
  try {
    yield put(isJobStatsLoading(true));

    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      token,
      url: `/api/job/${jobId}/stats/?${value}`
    });

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(fetchJobCandidatesStatsResponse(response.data));
    }
  } catch (error) {
    console.error("Could not get job stats", error);
    yield put(fetchJobCandidatesStatsError("Could not get job stats"));
  } finally {
    yield put(isJobStatsLoading(false));
  }
}

export function* fetchJobCandidatesStatsFilter({
  jobId = "",
  dateRange: { value = "" } = {},
  name = "",
  rank = ""
} = {}) {
  try {
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      token,
      url: `/api/job/${jobId}/stats/?${value}&name=${name}&rank=${rank}`
    });

    if (response && [200, 201, 204].includes(response.status)) {
      yield put(fetchJobCandidatesStatsResponse(response.data));
      yield put(fetchJobCandidatesStatsFilterResponse(response.data));
    }
  } catch (error) {
    console.error("Could not get job stats filter", error);
  }
}

export function* saveJobWorker({
  payload: {
    isUpdateJobUsersList = false,
    isUpdateJob = false,
    isQuestion = false,
    successCallback,
    ...rest
  } = {}
}) {
  try {
    const agency = yield select(getCurrentUser);
    const agencyKey = agency?.key;
    yield put(setLoadingScreen(true));

    let response;
    const { id = null } = rest;
    const token = yield select(getToken);

    if (id) {
      response = yield call(patchRequestWithToken, {
        url: `/api/job/${id}/update/`,
        token,
        payload: rest
      });

      yield put(updateJobResponse(response.data));
    } else {
      response = yield call(postRequestWithToken, {
        url: "/api/job/create/",
        token,
        payload: rest
      });

      yield put(saveJobResponse(response.data));
    }
    yield call(handleOnboardingWorker, {
      payload: {
        key: agencyKey
      }
    });

    yield put(clearSetupState());
    yield put(clearQuestionsState());
    yield put(clearPreviewState());

    if (isUpdateJobUsersList) {
      yield put(updateJobUsersListResponse(rest.reviewer));
      yield put(fetchCurrentJobResponse(response.data));
    } else if (isUpdateJob) {
      yield put(fetchCurrentJobResponse(response.data));
    } else {
      yield call(history.push, `/jobs/invite/${response.data.key}`);
    }

    if (typeof successCallback === "function") successCallback();
  } catch (error) {
    let errorText =
      "There is a problem with saving job. Recheck all info and push next / save button into all steps";

    console.log("[saveJobWorker error]", error);

    const agencyKey = yield select(getCurrentUserId);
    logErrors({ error, payload: { ...rest }, context: { userId: agencyKey, ...rest, type: "recruiter" } });

    if (!error?.response) {
      yield put(saveJobError(errorText));
      return;
    }

    const { status, data } = error?.response || {};

    if (status === 400) {
      const fieldErrorsKeys = Object.keys(data);

      const fieldErrorsText = fieldErrorsKeys
        .map(errorKey => {
          const hasInnerFields = Object.keys(data[errorKey]).length > 0;

          const specifiFieldsKeys = Object.keys(data[errorKey]);

          if (hasInnerFields && errorKey === "questions") {
            const listOfSpecificFields = specifiFieldsKeys?.map(number => {
              if (typeof data?.[errorKey]?.[number] === "string") {
                return `${capitalize(errorKey)}: ${data[
                  errorKey
                ]?.toString()}\n\n`;
              }

              const fieldParams = Object.entries(data[errorKey][number]);

              const fieldErrorText = `${errorKey}-${Number(number) + 1}: ${
                fieldParams[0]
              }`;

              return fieldErrorText;
            });

            return listOfSpecificFields;
          }

          if (
            hasInnerFields &&
            ["reviewers_to_notify", "reviewers"].includes(errorKey)
          ) {
            const hasArray = Array.isArray(data[errorKey][0]);

            if (hasArray) {
              return `${data[errorKey][0].toString()}: ${errorKey}`;
            }

            return data[errorKey].map(
              errorMessage => `Unable to save: ${errorMessage}`
            );
          }

          if (hasInnerFields && isArray(specifiFieldsKeys)) {
            return `${capitalize(errorKey)}: ${data[errorKey].toString()}`;
          }

          return data[errorKey] || "";
        });

      yield put(saveJobErrorWithFields(fieldErrorsText));

      errorText = fieldErrorsText?.join(" ");
    }

    if (status === 400 && data.non_field_errors) {
      errorText = `Unfortunately ${data.non_field_errors}. Please note you cannot add yourself as a reviewer.`;
    }

    yield put(saveJobError(errorText));

    if (isQuestion) throw new Error(errorText);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* duplicateJobSuccessWorker() {
  yield delay(3000);
  yield put(clearSuccessMessage());
}

export function* duplicateJobWorker({
  payload: { value, currentUserKey, filter }
}) {
  try {
    yield put(isJobsLoading(true));

    const token = yield select(getToken);

    const response = yield call(postRequestWithToken, {
      url: `/api/job/${value}/duplicate/`,
      token,
      value
    });

    if (response.status === 200) {
      yield call(fetchJobsWorker, { payload: { key: currentUserKey, filter } });

      const successText = `Job ${response.data.title} was successfully added.`;

      yield put(clearErrors());
      yield put(duplicateJobResponse(response.data));
      yield call(handleOnboardingWorker, {
        payload: {
          key: currentUserKey
        }
      });
      yield put(duplicateJobSuccess(successText));
    }
  } catch (error) {
    const maximumJobs = yield select(getJobsMaximum);
    let errorText = `Your plan allows ${maximumJobs} active interviews. Please upgrade to create more interviews, or disable an active interview to create another.`;

    if (error.response.data.detail) {
      errorText = error.response.data.detail;
    }

    logErrors({ error, context: { userId: currentUserKey, type: "recruiter" } });

    yield put(duplicateJobError(errorText));
  } finally {
    yield put(isJobsLoading(false));
  }
}

export function* sendSpreadsheetWorker({ payload: { key = "", file = {} } }) {
  try {
    yield put(isJobsLoading(true));
    yield put(sendSpreadsheetError(null));

    const token = yield select(getToken);
    const method = "PUT";
    const sreadsheetFile = { file };

    const response = yield uploadRequestWithToken({
      url: `/api/job/${key}/candidates/parse/`,
      token,
      method,
      payload: sreadsheetFile
    });

    if (response.data?.data?.length) {
      yield put(
        sendSpreadsheetResponse({
          data: response.data?.data,
          parse: {
            total: response.data?.data?.length,
            success: response.data?.data?.length
          }
        })
      );
    } else {
      const errorMessage =
        !response.data?.data?.length && !response.data?.errors?.length
          ? "emptyCandidatesList"
          : "sendSpreadsheetError";
      yield put(sendSpreadsheetError(errorMessage));
    }
  } catch (error) {
    const agencyKey = yield select(getCurrentUserId);
    logErrors({ error, context: { userId: agencyKey, jobId: key, type: "recruiter" } });

    if (error.response?.data) {
      const { data = [], errors = [] } = error.response.data;
      yield put(
        sendSpreadsheetResponse({
          data,
          parse: {
            total: data?.length,
            success: errors.filter(item => isEmpty(item)).length
          }
        })
      );
    } else {
      console.log("[sendSpreadsheetError]", error);
      yield put(sendSpreadsheetError(error.data));
    }
  } finally {
    yield put(isJobsLoading(false));
  }
}

export function* sendInvitesWorker({ payload = "" }) {
  try {
    yield put(isSendingUploading(true));

    const token = yield select(getToken);
    const candidatesForInvites = yield select(getJobInvitesData);
    const interviewers = candidatesForInvites.map(c =>
      pick(c, ["email", "name", "phone"]));
    const interviewersWithValidPhone = interviewers.map(interviewer => ({
      ...interviewer,
      phone: checkPhoneValidity(interviewer.phone) ? interviewer.phone : ""
    }));
    const payloadWithInvites = { interviewers: interviewersWithValidPhone };

    const response = yield postRequestWithToken({
      url: `/api/job/${payload}/candidates/`,
      token,
      payload: payloadWithInvites
    });

    yield put(sendInvitesResponse(response.data));
    yield put(clearJobInvites());
  } catch (error) {
    const isInterviewersErrorExist = error.response.data.interviewers;

    if (isInterviewersErrorExist) {
      yield put(sendInvitesError(error.response.data.interviewers));
    }

    console.log("[sendInvitesWorker]", error);
  } finally {
    yield put(isSendingUploading(false));
  }
}

export function* fetchCandidatesListWorker({
  payload: { jobId = "", name = "", rank = "" }
}) {
  try {
    yield put(isCandidatesStatsLoading(true));

    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/job/${jobId}/candidates/?name=${name}&rank=${rank}`,
      token
    });

    yield call(fetchJobCandidatesStatsFilter, {
      jobId,
      dateRange: { value: "" },
      name,
      rank
    });

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(fetchCandidatesListResponse(response.data));
    }
  } catch (error) {
    console.log("[fetchCandidatesListError]", error);
    yield put(
      fetchCandidatesListError("There is problem with fetching users list.")
    );
  } finally {
    yield put(isCandidatesStatsLoading(false));
  }
}

export function* fetchJobUsersListWorker({
  payload: { jobId = "", query = "" }
}) {
  try {
    if (jobId) {
      yield put(isJobUsersLoading(true));

      const token = yield select(getToken);
      const queryParams =
        // eslint-disable-next-line no-nested-ternary
        query === null ? "?query=" : query ? `?query=${query}` : "";
      const response = yield getRequestWithToken({
        url: `/api/job/${jobId}/accessing-users/${queryParams}`,
        token
      });

      yield put(fetchJobUsersListResponse({ ...response.data, query }));
    }
  } catch (error) {
    console.log("[fetchJobUsersListError]", error);
    yield put(
      fetchJobUsersListError("There is problem with fetching users list.")
    );
  } finally {
    yield put(isJobUsersLoading(false));
  }
}

export function* fetchNextJobUsersListWorker() {
  try {
    const next = yield select(getNextJobUsers);
    if (!next) return;

    yield put(isNextJobUsersLoading(true));
    const token = yield select(getToken);
    const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
    const response = yield getRequestWithToken({
      url,
      token
    });

    yield put(fetchNextJobUsersListResponse(response.data));
  } catch (error) {
    console.log("[fetchNextJobUsersListError]", error);
    yield put(
      fetchNextJobUsersListError("There is problem with fetching users list.")
    );
  } finally {
    yield put(isNextJobUsersLoading(false));
  }
}

export function* fetchCandidatesByStatusListWorker({
  payload: { jobId = "", isNext = false }
}) {
  try {
    yield put(isCandidatesStatsLoading(true));

    const token = yield select(getToken);
    const userCurrentJob = yield select(getUserCurrentJob);
    const statusJob = userCurrentJob.status;

    let response;

    if (isNext) {
      const pagination = yield select(getUserCurrentInterviewersPagination);

      if (pagination) {
        response = yield getFullUrlRequestWithToken({
          fullUrl: pagination,
          token
        });
      }
    } else {
      response = yield getRequestWithToken({
        url: `/api/job/${jobId}/candidates/status-keys/?status=${statusJob}`,
        token
      });
    }

    if ([200, 201]?.includes(response?.status)) {
      yield put(fetchCandidatesByStatusListResponse(response?.data));
    }
  } catch (error) {
    console.log("[fetchCandidatesByStatusListError]", error);
    yield put(
      fetchCandidatesByStatusListError(
        "There is problem with fetching users list."
      )
    );
  } finally {
    yield put(isCandidatesStatsLoading(false));
  }
}

export function* setSelectedCandidatesByStatusWorker({
  payload: { jobId = "", status = "", rank = "" }
}) {
  try {
    const rankQuery = rank ? `&rank=${rank}` : "";

    yield put(setSelectedCandidatesLoading(true));
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/job/${jobId}/candidates/status-keys/?${status}${rankQuery}`,
      token
    });

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(setSelectedCandidatesByStatusResponse(response?.data));
    }
  } catch (error) {
    console.log("[setSelectedCandidatesByStatusError]", error);
  } finally {
    yield put(setSelectedCandidatesLoading(false));
  }
}

export function* fetchNextCandidatesListWorker() {
  try {
    const next = yield select(getNextCandidates);
    const selectAllValues = yield select(getHasSelectAllStatus);
    const selected = yield select(getSelectedCandidates);
    const selectAllKeys = Object.keys(selectAllValues);
    if (!next) return;

    yield put(isNextCandidatesLoading(true));
    const token = yield select(getToken);
    const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
    const response = yield getRequestWithToken({
      url,
      token
    });

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(fetchNextCandidatesListResponse(response.data));

      yield all(
        selectAllKeys.map(a => {
          if (selectAllValues[a]) {
            return put(
              setSelectedCandidates(
                uniqBy([
                  ...new Set([
                    ...selected,
                    ...response.data?.results?.[
                      a === "received" ? "to_review" : a
                    ]?.map(({ key, status }) => ({
                      id: key,
                      status
                    }))
                  ])
                ])
              )
            );
          }
        })
      );
    }
  } catch (error) {
    console.log("[fetchNextCandidatesListError]", error);
    yield put(
      fetchNextCandidatesListError("There is problem with fetching users list.")
    );
  } finally {
    yield put(isNextCandidatesLoading(false));
  }
}

export function* fetchCandidateHistoryWorker({ payload: { userId = "" } }) {
  try {
    yield put(fetchCandidateHistoryLoading(true));

    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/job/candidate/${userId}/history/`,
      token
    });

    yield put(fetchCandidateHistoryResponse(response.data));
  } catch (error) {
    console.log("[fetchCandidateHistoryError]", error);
    yield put(
      fetchCandidateHistoryError("There is problem with fetching users list.")
    );
  } finally {
    yield put(fetchCandidateHistoryLoading(false));
  }
}

export function* fetchCurrentJobWorker({ payload = "" }) {
  try {
    yield put(isJobsLoading(true));
    yield put(fetchCurrentJobResponse({}));
    if (!payload) return;

    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/job/${payload}/`,
      token
    });
    yield put(fetchCurrentJobResponse(response.data));
  } catch (error) {
    logErrors({ error, context: { jobId: payload, type: "recruiter" } });

    if (
      [JOB_STATUSES.CLOSED, JOB_STATUSES.EXPIRED_CLOSED].includes(error?.response?.data?.status)
    ) {
      yield call(history.push, "/interview-closed");
      return;
    }

    if (error?.response?.status === 404) {
      yield call(history.push, "/page-not-found");
      return;
    }

    yield put(fetchCurrentJobError("Cant fetch current job"));
  } finally {
    yield put(isJobsLoading(false));
  }
}

export function* fetchUserCurrentJobWorker({
  payload: { jobId, userId, backPath } = {}
}) {
  try {
    yield put(isJobsLoading(true));
    yield put(isJobCandidateLoading(true));
    yield put(isCommentsLoading(true));

    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/job/candidate/${userId}/`,
      token
    });
    yield put(setSidebarCommentsData(response.data?.comments || []));

    yield put(fetchUserCurrentJobResponse(response.data));

    yield call(fetchCandidateScoreByCardIdWorker, {
      payload: {
        draftKey: response?.data?.draft_score_key
      }
    });

    yield call(fetchCandidateScoresWorker, {
      payload: {
        userId
      }
    });

    if (
      response?.data?.is_locked &&
      response?.data?.status !== CANDIDATE_STATUSES.INVITED
    ) {
      yield put(fetchUserCurrentJobError(response?.data?.error));
      return history.push(
        "/plans?utm_source=internal&utm_medium=user&utm_campaign=trial_complete"
      );
    }

    const hasExistingResults = yield select(getUserCurrentInterviewersList);

    yield call(fetchCandidatesByStatusListWorker, {
      payload: {
        jobId,
        isNext: hasExistingResults?.length > 0
      }
    });

    if (response.data?.is_viewed === false) {
      const responseViewed = yield putRequestWithToken({
        url: `/api/job/candidate/${userId}/viewed/`,
        token
      });

      yield put(fetchUserCurrentJobResponse(responseViewed.data));
    }
  } catch (error) {
    if (error.response.status === 404) {
      return history.push(backPath ? `/not-access/?back=${backPath}` : "/page-not-found");
    }

    console.log("[sentIvitesError]", error);
    yield put(fetchUserCurrentJobError("Cant fetch current job"));
  } finally {
    yield put(isJobsLoading(false));
    yield put(isJobCandidateLoading(false));
    yield put(isCommentsLoading(false));
  }
}

export function* deleteCandidateWorker({
  payload: { userId, jobId, agency } = {}
}) {
  try {
    yield put(setLoadingScreen(true));
    const token = yield select(getToken);

    yield deleteRequestWithToken({
      url: `/api/job/candidate/${userId}/delete/`,
      token
    });

    yield put(deleteCandidateFromInterviewersList(userId));

    yield call(fetchJobsWorker, { payload: { key: agency } });

    yield call(history.push, `/jobs/overview/${jobId}`);
  } catch (error) {
    console.error("Could not delete candidate", error);
    yield put(deleteCandidateResponseError(error));
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* sendReminderCandidateWorker({ payload: { userId, jobId } }) {
  try {
    yield put(setLoadingScreen(true));
    const token = yield select(getToken);

    yield postRequestWithToken({
      url: `/api/job/candidate/${userId}/remind/`,
      token
    });
    yield put(fetchCandidateHistory({ userId }));
    yield put(fetchCandidateInformation({ userId, jobId, backPath: "" }));
  } catch (error) {
    console.error("send reminder candidate error", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* updateCandidateStatusWorker({
  payload: {
    userId = "",
    isAccept = null,
    rejection_type = "",
    widthrawal_type = "",
    status,
    agency,
    jobId,
    callback
  } = {}
}) {
  try {
    yield put(setLoadingScreen(true));
    const token = yield select(getToken);

    const response = yield patchRequestWithToken({
      url: `/api/job/candidate/${userId}/`,
      token,
      payload: {
        status,
        ...(isAccept
          ? null
          : {
            rejection_type,
            widthrawal_type
          })
      }
    });

    yield put(updateCandidateStatusResponse(response.data));
    yield call(fetchCandidateHistoryWorker, { payload: { userId } });
    yield call(fetchJobsWorker, { payload: { key: agency } });
    yield call(fetchCandidatesByStatusListWorker, { payload: { jobId } });
    if (typeof callback === "function") callback();
  } catch (error) {
    if (error.response.status === 401) {
      yield put(updateCandidateStatusError("Could not update user status"));
      console.error("Could not update user status", error);
      return history.push("/");
    }
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* fetchCandidateIdvPdfWorker({ payload: { userId, jobId } }) {
  try {
    yield put(fetchCandidateIdvPdfLoading(true));
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/questions/job/${jobId}/interviewer/${userId}/idv-pdf/`,
      token
    });

    if (response?.data?.remote_link) {
      yield put(fetchCandidateIdvPdfResponse(response?.data?.remote_link));
    }
  } catch (error) {
    logErrors({ error, context: { userId, jobId, type: "recruiter" } });
    yield put(fetchCandidateIdvPdfError(error?.message));
  } finally {
    yield put(fetchCandidateIdvPdfLoading(false));
  }
}

function* fetchCandidateIdvImagesWorker({ payload: { imageId, type } = {} }) {
  try {
    if (type === "document") {
      yield put(fetchCandidateIdvDocumentImageLoading(true));
    } else {
      yield put(fetchCandidateIdvIdentityImageLoading(true));
    }

    const token = yield select(getToken);

    const response = yield call(getRequestWithToken, {
      url: `/api/idv/${type}/media/${imageId}/`,
      token,
      responseType: "arraybuffer"
    });

    if (response.status === 200) {
      const buffer = new Uint8Array(response.data);
      const blob = new Blob([buffer], { type: "image/jpeg" });
      const imgUrl = window.URL.createObjectURL(blob);

      if (type === "document") {
        yield put(fetchCandidateIdvDocumentImageResponse(imgUrl));
      } else {
        yield put(fetchCandidateIdvIdentityImageResponse(imgUrl));
      }
    }
  } catch (error) {
    const agencyKey = yield select(getCurrentUserId);
    logErrors({ error, context: { userId: agencyKey, type: "recruiter" } });

    if (type === "document") {
      yield put(fetchCandidateIdvDocumentImageError(error?.message));
    } else {
      yield put(fetchCandidateIdvIdentityImageError(error?.message));
    }
  } finally {
    if (type === "document") {
      yield put(fetchCandidateIdvDocumentImageLoading(false));
    } else {
      yield put(fetchCandidateIdvIdentityImageLoading(false));
    }
  }
}

function* fetchCandidateIdvWorker({ payload: { userId, jobId } = {} }) {
  try {
    yield put(fetchCandidateIdvLoading(true));
    const token = yield select(getToken);

    const response = yield call(getRequestWithToken, {
      url: `/api/job/candidate/${userId}/idv-data/`,
      token
    });

    if (response.status === 200) {
      yield put(fetchCandidateIdvResponse(response.data));

      if (
        response?.data?.documents?.[0]?.images_id &&
        response?.data?.identity?.image_id
      ) {
        yield call(fetchCandidateIdvImagesWorker, {
          payload: {
            imageId: response?.data?.documents?.[0]?.images_id?.[0],
            type: "document"
          }
        });
        yield call(fetchCandidateIdvImagesWorker, {
          payload: {
            imageId: response.data?.identity?.image_id,
            type: "identity"
          }
        });
        yield call(fetchCandidateIdvPdfWorker, { payload: { userId, jobId } });
      }
    }
  } catch (error) {
    logErrors({ error, context: { userId, jobId, type: "recruiter" } });

    yield put(fetchCandidateIdvError(error.message));
  } finally {
    yield put(fetchCandidateIdvLoading(false));
  }
}

export function* fetchCandidateLatestAnswerWorker({
  payload: { userId = "", jobId = "", answerId = "" }
}) {
  let response = {};
  try {
    const answers = yield select(getCandidateAnswers);
    const token = yield select(getToken);

    response = yield call(getRequestWithToken, {
      url: `/api/questions/job/${jobId}/interviewer/${userId}/answer/${answerId}/`,
      token
    });

    const newAnswers = answers.map(answer =>
      answer?.key === response?.data?.key ? response.data : answer);

    yield put(fetchCandidateAnswersResponse(newAnswers));
  } catch (error) {
    console.log(
      `Failed to fetch candidate's answer for job id ${jobId} and user id ${userId} and answer id ${answerId}`
    );
    logErrors({ error, context: { userId, jobId, answerId, type: "recruiter" } });
  }

  return response.data;
}

export function* fetchCandidateAnswersWorker({
  payload: { userId = "", jobId = "" }
}) {
  try {
    yield put(isJobCandidateLoading(true));
    yield put(fetchCandidateAnswersResponse([]));
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/questions/job/${jobId}/interviewer/${userId}/`,
      token
    });

    yield put(fetchCandidateAnswersResponse(response.data));

    const hasIdvCheck = response?.data?.some(question =>
      ALL_IDENTITY_VERIFICATION_TYPES?.includes(question?.question?.answer_type));

    if (hasIdvCheck && userId !== "" && jobId !== "") {
      yield call(fetchCandidateIdvWorker, { payload: { userId, jobId } });
    }
  } catch (error) {
    yield put(fetchCandidateAnswersError("Incorect user"));
    console.error("Could not get candidate answers", error);
  } finally {
    yield put(isJobCandidateLoading(false));
  }
}

export function* fetchSuggestionsWorker({ payload }) {
  try {
    if (!payload || !payload?.length || payload === "") {
      return yield put(setSuggestions([]));
    }
    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/questions/autocomplete/?keywords=${payload}`,
      token
    });

    yield put(setSuggestions(response.data));
  } catch (error) {
    console.error("Could not get suggestions", error);
  }
}

export function* saveSetupInfoWorker({
  payload: {
    blob,
    isOwn,
    mediaExtension,
    ownVideo,
    ownVideoUrl,
    company,
    callback,
    ...rest
  }
}) {
  try {
    yield put(setLoadingScreen(true));
    yield put(setVideoError(null));
    let urlVideoFull = ownVideo;
    let urlVideo = ownVideoUrl;

    if (isOwn && blob && blob.size && blob.size >= 0) {
      const token = yield select(getToken);
      const uuid = uuidv4();

      const response = yield getRequestWithToken({
        url: `/api/job/introduction/${
          company.value
        }/upload-info/?file_name=${uuid}.${mediaExtension || "webm"}`,
        token
      });

      if (response?.status === 200) {
        const { url = "", review_link = "", fields = {} } = response.data;

        const responseVideoUpload = yield uploadRequestWithToken({
          fullUrl: url,
          method: "POST",
          payload: {
            ...fields,
            file: blob
          }
        });

        if (
          responseVideoUpload.status === 204 ||
          responseVideoUpload.status === 200
        ) {
          urlVideoFull = review_link;
          urlVideo = fields.key;
        }
      }
    }

    if (typeof callback === "function") {
      callback({
        ...rest,
        isOwn: Boolean(
          isOwn && ((blob && blob.size && blob.size >= 0) || urlVideoFull)
        ),
        company,
        ownVideo: urlVideoFull,
        ownVideoUrl: urlVideo,
        blob: null
      });
    }
  } catch (error) {
    const errorMessage = error.message || "errors.videoDefaultError";

    const agencyKey = yield select(getCurrentUserId);
    logErrors({ error, payload: { ...rest }, context: { userId: agencyKey, ...rest, type: "recruiter" } });

    yield put(setVideoError(errorMessage));
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* updateCandidatesListWorker({
  payload: { jobId, action, name, rank }
}) {
  try {
    yield put(setLoadingScreen(true));
    const token = yield select(getToken);
    const selectedCandidates = yield select(getSelectedCandidates);
    const selectAllStatus = yield select(getHasSelectAllStatus);
    const excludedCandidates = yield select(getExcludedCandidates);

    yield putRequestWithToken({
      url: `/api/job/${jobId}/candidates/bulk/status/update/`,
      token,
      payload: {
        candidates: {
          received: selectAllStatus.received
            ? ["all"]
            : selectedCandidates
              .filter(a => a.status === "Received")
              ?.map(a => a.id),
          invited: selectAllStatus.invited
            ? ["all"]
            : selectedCandidates
              .filter(a => a.status === "Invited")
              ?.map(a => a.id),
          accepted: selectAllStatus.accepted
            ? ["all"]
            : selectedCandidates
              .filter(a => a.status === "Accepted")
              ?.map(a => a.id),
          rejected: selectAllStatus.rejected
            ? ["all"]
            : selectedCandidates
              .filter(a => a.status === "Rejected")
              ?.map(a => a.id),
          to_exclude: excludedCandidates?.map(a => a.id) || [],
          rank
        },
        action,
        ...(rank
          ? {
            filters: [
              {
                field: "rounded_avg_rank",
                value: [rank]
              }
            ]
          }
          : {})
      }
    });
    yield put(fetchCandidatesList({ jobId, name, rank }));
  } catch (error) {
    console.log("[updateCandidatesListError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* downloadCandidatesListWorker({ payload: { jobId } }) {
  try {
    yield put(setLoadingScreen(true));
    yield put(updateIsOpenVideoArchives(true));

    const token = yield select(getToken);
    const selectedCandidates = yield select(getSelectedCandidates);
    const selectAllStatus = yield select(getHasSelectAllStatus);
    const excludedCandidates = yield select(getExcludedCandidates);

    const response = yield postRequestWithToken({
      url: `/api/job/${jobId}/candidates/bulk/info/csv/`,
      token,
      payload: {
        received: selectAllStatus.received
          ? ["all"]
          : selectedCandidates
            .filter(a => a.status === "Received")
            ?.map(a => a.id),
        invited: selectAllStatus.invited
          ? ["all"]
          : selectedCandidates
            .filter(a => a.status === "Invited")
            ?.map(a => a.id),
        accepted: selectAllStatus.accepted
          ? ["all"]
          : selectedCandidates
            .filter(a => a.status === "Accepted")
            ?.map(a => a.id),
        rejected: selectAllStatus.rejected
          ? ["all"]
          : selectedCandidates
            .filter(a => a.status === "Rejected")
            ?.map(a => a.id),
        to_exclude: excludedCandidates?.map(a => a.id) || []
      }
    });

    const { data: { firebase_folder_path } = {} } = response;

    if (firebase_folder_path) yield put(saveCandidatesExportLink({ link: firebase_folder_path }));
  } catch (error) {
    console.log("[downloadCandidatesListError]", error);
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* fetchCandidateInformationWorker({
  payload: { userId, jobId, backPath }
}) {
  try {
    yield put(setCandidateInformationLoading(true));
    yield put(fetchCurrentJob(jobId));
    yield call(fetchUserCurrentJobWorker, {
      payload: { userId, jobId, backPath }
    });
    yield call(fetchCandidateAnswersWorker, { payload: { jobId, userId } });
  } catch (error) {
    console.log("[fetchCandidateInformationWorker]", error);
  } finally {
    yield put(setCandidateInformationLoading(false));
  }
}

// ATS related apis

export function* fetchAvailableAtsJobsWorker({
  payload: { agencyKey, atsKey, params = {}, cancelToken = null }
}) {
  try {
    const token = yield select(getToken);
    let status = null;
    let data = null;

    if (params?.next) {
      const {
        status: currentStatus,
        data: currentData
      } = yield getFullUrlRequestWithToken({
        fullUrl: params.next,
        token,
        cancelTokenSource: cancelToken
      });

      status = currentStatus;
      data = currentData;
    } else {
      const {
        status: currentStatus,
        data: currentData
      } = yield getRequestWithToken({
        url: `/api/marketplace/integration/agency/${agencyKey}/service/${atsKey}/jobs/`,
        token,
        params,
        cancelTokenSource: cancelToken
      });

      status = currentStatus;
      data = currentData;
    }

    if ([200, 201].includes(status)) {
      if (params?.search) {
        yield put(filterAtsJobsResponse({ ...data, atsKey }));
      } else if (!params?.next) {
        yield put(updateAvailableAtsJobs({ ...data, atsKey }));
      } else {
        yield put(updateNextAvailableAtsJobs({ ...data, atsKey }));
      }
    }
  } catch (errors) {
    logErrors({ error: errors, context: { userId: agencyKey, type: "recruiter" } });
  }
}

export function* fetchNextAvailableAtsJobsWorker({
  payload: { agencyKey, availableJobs, jobId, cancelToken = null }
}) {
  try {
    yield put(getNextAtsJobsLoading(true));

    yield all(
      availableJobs?.atsKeys?.map(ats => {
        if (!ats.next) return null;

        return fetchAvailableAtsJobsWorker({
          payload: {
            agencyKey,
            atsKey: ats.key,
            params: {
              next: ats.next,
              interview_key: jobId
            },
            cancelToken
          }
        });
      })
    );
  } catch (errors) {
    logErrors({ error: errors, context: { userId: agencyKey, jobId, type: "recruiter" } });
  } finally {
    yield put(getNextAtsJobsLoading(false));
  }
}

export function* fetchFilteredAvailableAtsJobsWorker({
  payload: { agencyKey, availableJobs, params, cancelToken }
}) {
  try {
    yield put(filterAtsJobsLoading(true));
    yield all(
      availableJobs?.atsKeys?.map(ats =>
        fetchAvailableAtsJobsWorker({
          payload: {
            agencyKey,
            atsKey: ats.key,
            params,
            cancelToken
          }
        }))
    );
  } catch (errors) {
    logErrors({ error: errors, context: { userId: agencyKey, type: "recruiter" } });
  } finally {
    yield put(filterAtsJobsLoading(false));
  }
}

export function* fetchAtsJobDetailsWorker({
  payload: { jobId, agencyKey, shouldSkipLoading = false, params }
}) {
  try {
    if (!shouldSkipLoading) {
      yield put(isJobAtsLoading(true));
    }

    const token = yield select(getToken);

    const { status, data } = yield getRequestWithToken({
      token,
      url: `/api/marketplace/integration/interview/${jobId}/callbacks/ats/`
    });

    if ([200, 201].includes(status)) {
      yield put(getJobAtsDetailsResponse(data));

      yield all(
        data.map(ats =>
          fetchAvailableAtsJobsWorker({
            payload: {
              agencyKey,
              atsKey: ats.key,
              params
            }
          }))
      );
    }
  } catch (error) {
    logErrors({ error, context: { userId: agencyKey, jobId, type: "recruiter" } });
  } finally {
    yield put(isJobAtsLoading(false));
  }
}

export function* fetchAtsJobStagesWorker({
  payload: { agencyKey, atsId, atsJobId, cancelToken = null }
}) {
  try {
    yield put(getAvailableAtsJobStagesLoading(true));
    const token = yield select(getToken);

    const { status, data } = yield getRequestWithToken({
      url: `/api/marketplace/integration/agency/${agencyKey}/service/${atsId}/stages/`,
      token,
      params: { job_id: atsJobId },
      cancelTokenSource: cancelToken
    });

    if ([200, 201].includes(status)) {
      yield put(getAvailableAtsJobStagesResponse(data));
    }
  } catch (errors) {
    logErrors({ error: errors, context: { userId: agencyKey, type: "recruiter" } });
  } finally {
    yield put(getAvailableAtsJobStagesLoading(false));
  }
}

export function* subscribeToAtsJobAndStagesWorker({
  payload: { agencyKey, atsId, payload }
}) {
  try {
    const token = yield select(getToken);

    const { status, data } = yield postRequestWithToken({
      url: `/api/marketplace/integration/agency/${agencyKey}/service/${atsId}/subscribe/`,
      token,
      payload
    });

    if ([201, 200].includes(status)) {
      yield put(subscribeToAtsJobResponse({ callbacks: data, atsKey: atsId }));
    }
  } catch (errors) {
    logErrors({ error: errors, context: { userId: agencyKey, type: "recruiter" } });
  }
}

export function* updateConnectedAtsJobsAndStagesWorker({
  payload: { selectedJobsAndStages, agencyKey, jobId, callbackFn }
}) {
  try {
    yield put(subscribeToAtsJobLoading(true));
    const atsDetails = yield select(getJobAts);

    yield all(
      atsDetails?.atsConnected?.map(ats => {
        const result = selectedJobsAndStages
          .filter(a => a.atsKey === ats.key)
          .reduce((r, a) => {
            r[a.remote_interview_id] = r[a.remote_interview_id] || [];
            r[a.remote_interview_id].push(a);
            return r;
          }, Object.create(null));

        const remoteJobs = Object.values(result).map(remoteJob => ({
          remote_id: remoteJob[0].remote_interview_id,
          name: remoteJob[0].remote_interview_name,
          stages: remoteJob.map(a => ({
            remote_id: a.remote_stage_id,
            name: a.remote_stage_name
          }))
        }));

        return subscribeToAtsJobAndStagesWorker({
          payload: {
            agencyKey,
            atsId: ats.key,
            payload: {
              remote_jobs: remoteJobs,
              job: jobId,
              trigger: "new_response"
            }
          }
        });
      })
    );
  } catch (errors) {
    logErrors({ errors, context: { userId: agencyKey, jobId, type: "recruiter" } });
  } finally {
    if (typeof callbackFn === "function") callbackFn();
    yield put(subscribeToAtsJobLoading(false));
  }
}

export function* createJobWatcher() {
  yield takeEvery(saveJob, saveJobWorker);
  yield takeEvery(updateJobUsersList, saveJobWorker);
  yield takeEvery(duplicateJob, duplicateJobWorker);
  yield takeLatest(duplicateJobSuccess, duplicateJobSuccessWorker);
  yield takeEvery(fetchCompaniesForQuestion, fetchCompaniesForQuestionWorker);
  yield takeEvery(fetchLanguagesForQuestion, fetchLanguagesForQuestionWorker);
  yield takeEvery(fetchIndustries, fetchIndustriesWorker);
  yield takeEvery(fetchJobs, fetchJobsWorker);
  yield takeEvery(fetchJobsFilters, fetchJobsFiltersWorker);
  yield takeEvery(fetchLatestJobs, fetchLatestJobsWorker);
  yield takeEvery(fetchJobsStats, fetchJobsStatsWorker);
  yield takeEvery(fetchJobCandidatesStats, fetchJobCandidatesStatsWorker);
  yield takeEvery(fetchCurrentJob, fetchCurrentJobWorker);
  yield takeEvery(fetchUserCurrentJob, fetchUserCurrentJobWorker);
  yield takeLatest(fetchCandidatesList, fetchCandidatesListWorker);
  yield takeLatest(fetchJobUsersList, fetchJobUsersListWorker);
  yield takeLatest(fetchNextJobUsersList, fetchNextJobUsersListWorker);
  yield takeEvery(
    fetchCandidatesByStatusList,
    fetchCandidatesByStatusListWorker
  );
  yield takeEvery(fetchNextCandidatesList, fetchNextCandidatesListWorker);
  yield takeEvery(updateCandidatesList, updateCandidatesListWorker);
  yield takeEvery(downloadCandidatesList, downloadCandidatesListWorker);
  yield takeEvery(fetchCandidateAnswers, fetchCandidateAnswersWorker);
  yield takeEvery(fetchCandidateHistory, fetchCandidateHistoryWorker);
  yield takeEvery(updateCandidateStatus, updateCandidateStatusWorker);
  yield takeEvery(deleteCandidate, deleteCandidateWorker);
  yield takeEvery(sendReminderCandidate, sendReminderCandidateWorker);
  yield takeEvery(deleteJob, deleteJobWorker);
  yield takeEvery(restoreJob, restoreJobWorker);
  yield takeEvery(sendSpreadsheet, sendSpreadsheetWorker);
  yield takeEvery(sendInvites, sendInvitesWorker);
  yield takeEvery(saveSetupInfo, saveSetupInfoWorker);
  yield takeLatest(fetchSuggestions, fetchSuggestionsWorker);
  yield takeEvery(
    setSelectedCandidatesByStatus,
    setSelectedCandidatesByStatusWorker
  );
  yield takeEvery(fetchCandidateInformation, fetchCandidateInformationWorker);
  yield takeEvery(fetchCandidateIdv, fetchCandidateIdvWorker);
  yield takeEvery(fetchCandidateAnswer, fetchCandidateLatestAnswerWorker);
  yield takeEvery(fetchJobsCompaniesFilter, fetchJobsCompaniesFilterWorker);
  yield takeEvery(fetchJobsOwnersFilter, fetchJobsOwnersFilterWorker);
  yield takeEvery(fetchJobsStatusesFilter, fetchJobsStatusesFilterWorker);
  yield takeEvery(getJobAtsDetails, fetchAtsJobDetailsWorker);
  yield takeEvery(getAvailableAtsJobStages, fetchAtsJobStagesWorker);
  yield takeEvery(getNextAtsJobs, fetchNextAvailableAtsJobsWorker);
  yield takeEvery(subscribeToAtsJob, subscribeToAtsJobAndStagesWorker);
  yield takeEvery(
    updateConnectedAtsJobsAndStages,
    updateConnectedAtsJobsAndStagesWorker
  );
  yield takeEvery(filterAtsJobs, fetchFilteredAvailableAtsJobsWorker);
}

export default createJobWatcher;
